



import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import io from 'socket.io-client'; // Socket.io for real-time updates
import './BookList.css';

const socket = io('http://195.35.48.252:4000'); // Replace with your Socket.io server URL

const BookGraph = ({ book }) => {
  const [priceHistory, setPriceHistory] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    // Ensure price history is populated with the initial price
    setPriceHistory([book.price]);
    setLabels([new Date().toLocaleTimeString()]); // Add an initial timestamp

    // Listen for real-time price updates from the server
    socket.on('priceUpdate', (newPrice) => {
      setPriceHistory((prevHistory) => [...prevHistory, newPrice]);
      setLabels((prevLabels) => [...prevLabels, new Date().toLocaleTimeString()]);
    });

    // Cleanup when book prop changes or component unmounts
    return () => {
      setPriceHistory([]);
      setLabels([]);
      socket.off('priceUpdate');
    };
  }, [book]);

  const data = {
    labels: labels.length ? labels : ['Initial'], // Default label for empty chart
    datasets: [
      {
        label: 'Price (Fanon)',
        data: priceHistory.length ? priceHistory : [book.price], // Ensure there's at least one data point
        fill: false,
        backgroundColor: 'rgba(255,99,132,0.2)', // Lighter red background
        borderColor: 'rgba(255,99,132,1)', // Red line
        borderWidth: 2,
        tension: 0.4, // Add curve to the line for a smooth chart
        pointRadius: 3, // Small dots on data points
        pointHoverRadius: 5, // Larger dots on hover
        pointBackgroundColor: 'rgba(255,99,132,1)', // Red dots
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true, // Display X-axis labels for timestamps
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        beginAtZero: false, // Adjust based on price data
        title: {
          display: true,
          text: 'Price (Fanon)',
        },
        ticks: {
          callback: function (value) {
            return `${value.toLocaleString()} Fanon`; // Format ticks with units
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
    animation: {
      duration: 0, // Disable animation for real-time updates
    },
    responsive: true,
    maintainAspectRatio: false, // Allow chart to adapt to container size
  };

  return (
    <div className="book-graph" style={{ height: '300px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default BookGraph;
