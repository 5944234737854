


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './AddBookForm.css';

// const AddBookForm = () => {
//   const [title, setTitle] = useState('');
//   const [author, setAuthor] = useState('');
//   const [price, setPrice] = useState('');
//   const [category, setCategory] = useState('');
//   const [ebook, setEbook] = useState(null);
//   const [image, setImage] = useState(null);
//   const [biographyImage, setBiographyImage] = useState(null);
//   const [notification, setNotification] = useState('');
//   const [books, setBooks] = useState([]);
//   const [editingBookId, setEditingBookId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);



//   const booksPerPage = 8;

//   useEffect(() => {
//     const fetchBooks = async () => {
//       try {
//         const response = await axios.get('http://195.35.48.252:4000/api/books');
//         setBooks(response.data);
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };

//     fetchBooks();
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('author', author);
//     formData.append('price', price);
//     formData.append('category', category);
//     if (ebook) formData.append('ebook', ebook);
//     if (image) formData.append('image', image);
//     if (biographyImage) formData.append('biographyImage', biographyImage);

//     try {
//       let response;
//       if (editingBookId) {
//         response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) =>
//           prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
//         );
//       } else {
//         response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) => [...prevBooks, response.data]);
//       }
//       setNotification('Book added/updated successfully!');
//       setTitle('');
//       setAuthor('');
//       setPrice('');
//       setCategory('');
//       setEbook(null);
//       setImage(null);
//       setBiographyImage(null);
//       setEditingBookId(null);
//       setIsFormVisible(false);
//     } catch (error) {
//       setNotification('Error adding/updating book. Please try again.');
//     }
//   };

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     setEbook(selectedFile);
//   };

//   const handleImageChange = (e) => setImage(e.target.files[0]);
//   const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

//   const handleDelete = async (bookId) => {
//     try {
//       await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
//       setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
//       setNotification('Book deleted successfully!');
//     } catch (error) {
//       setNotification('Error deleting book. Please try again.');
//     }
//   };

//   const handleEdit = (book) => {
//     setTitle(book.title);
//     setAuthor(book.author);
//     setPrice(book.price);
//     setCategory(book.category);
//     setEditingBookId(book._id);
//     setIsFormVisible(true);
//   };



  

//   const handleShareLink = (book) => {
//     const shareableLink = `http://195.35.48.252:3000/book/${book._id}`;
//     alert(`Share this link: ${shareableLink}`);
//   };

//   const filteredBooks = books.filter((book) =>
//     book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     book.author.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
//   const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

//   return (
//     <div className="add-book-form-container">
//       {notification && <div className="notification">{notification}</div>}
//       <input
//         type="search"
//         placeholder="Search by title or author..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <br></br>
//       <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
//         {isFormVisible ? 'Cancel' : 'Add Book'}
//       </button>
//       <br></br>

//       {isFormVisible && (
//         <form onSubmit={handleSubmit}>
//           <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
//           <input type="text" placeholder="Author" value={author} onChange={(e) => setAuthor(e.target.value)} required />
//           <input type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} required />
//           <select value={category} onChange={(e) => setCategory(e.target.value)} required>
//             <option value="">Select Category</option>
//             <option value="Fiction">Fiction</option>
//             <option value="Non-Fiction">Non-Fiction</option>
//           </select>
//           <label className="label">Upload eBook PDF</label>
//           <input type="file" accept="application/pdf" onChange={handleFileChange} />
//           <label className="label">Upload eBook Cover</label>
//           <input type="file" accept="image/*" onChange={handleImageChange} />
//           <label className="label">Upload Author Biography Image</label>
//           <input type="file" accept="image/*" onChange={handleBiographyImageChange} />
//           <button type="submit">{editingBookId ? 'Update Book' : 'Add Book'}</button>
//         </form>
//       )}

//       <div className="book-list">
//         {paginatedBooks.map((book) => (
//           <div key={book._id} className="book-item">
//             {book.imagePath && (
//               <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
//             )}
//             <h3>{book.title}</h3>
//             <p>Author: {book.author}</p>
//             <p>Price: KES {book.price} </p>
//             <p>Category: {book.category}</p>
//             <button onClick={() => handleEdit(book)}>Edit</button>
//             <button onClick={() => handleDelete(book._id)}>Delete</button>
//             <button onClick={() => handleShareLink(book)}>Copy Link</button>
//           </div>
//         ))}
//       </div>

//       <div className="pagination">
//         {Array.from({ length: totalPages }, (_, i) => (
//           <button
//             key={i + 1}
//             onClick={() => setCurrentPage(i + 1)}
//             className={currentPage === i + 1 ? 'active' : ''}
//           >
//             {i + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddBookForm;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './AddBookForm.css';

// const AddBookForm = () => {
//   const [title, setTitle] = useState('');
//   const [author, setAuthor] = useState('');
//   const [price, setPrice] = useState('');
//   const [category, setCategory] = useState('');
//   const [ebook, setEbook] = useState(null);
//   const [image, setImage] = useState(null);
//   const [biographyImage, setBiographyImage] = useState(null);
//   const [discountRange, setDiscountRange] = useState(''); // New state for discount
//   const [notification, setNotification] = useState('');
//   const [books, setBooks] = useState([]);
//   const [editingBookId, setEditingBookId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);

//   const booksPerPage = 8;

//   // Define the discount range options
//   const discountOptions = [
//     { label: '5-10%', value: 0.10 },
//     { label: '10-15%', value: 0.15 },
//     { label: '15-20%', value: 0.20 },
//     { label: '20-30%', value: 0.30 },
//   ];

//   useEffect(() => {
//     const fetchBooks = async () => {
//       try {
//         const response = await axios.get('http://195.35.48.252:4000/api/books');
//         setBooks(response.data);
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };

//     fetchBooks();
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Calculate minimum price based on the selected discount range
//     const minPrice = price - (price * (discountRange || 0));
//     if (price < minPrice) {
//       setNotification(`Price cannot go below the selected discount threshold.`);
//       return;
//     }

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('author', author);
//     formData.append('price', price);
//     formData.append('category', category);
//     formData.append('discountRange', discountRange); // Include discount in submission
//     if (ebook) formData.append('ebook', ebook);
//     if (image) formData.append('image', image);
//     if (biographyImage) formData.append('biographyImage', biographyImage);

//     try {
//       let response;
//       if (editingBookId) {
//         response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) =>
//           prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
//         );
//       } else {
//         response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) => [...prevBooks, response.data]);
//       }
//       setNotification('Book added/updated successfully!');
//       setTitle('');
//       setAuthor('');
//       setPrice('');
//       setCategory('');
//       setDiscountRange(''); // Reset discount range
//       setEbook(null);
//       setImage(null);
//       setBiographyImage(null);
//       setEditingBookId(null);
//       setIsFormVisible(false);
//     } catch (error) {
//       setNotification('Error adding/updating book. Please try again.');
//     }
//   };

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     setEbook(selectedFile);
//   };

//   const handleImageChange = (e) => setImage(e.target.files[0]);
//   const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

//   const handleDelete = async (bookId) => {
//     try {
//       await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
//       setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
//       setNotification('Book deleted successfully!');
//     } catch (error) {
//       setNotification('Error deleting book. Please try again.');
//     }
//   };

//   const handleEdit = (book) => {
//     setTitle(book.title);
//     setAuthor(book.author);
//     setPrice(book.price);
//     setCategory(book.category);
//     setDiscountRange(book.discountRange); // Set discount range for editing
//     setEditingBookId(book._id);
//     setIsFormVisible(true);
//   };

//   const handleShareLink = (book) => {
//     const shareableLink = `http://195.35.48.252:3000/book/${book._id}`;
//     alert(`Share this link: ${shareableLink}`);
//   };

//   const filteredBooks = books.filter((book) =>
//     book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     book.author.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
//   const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

//   return (
//     <div className="add-book-form-container">
//       {notification && <div className="notification">{notification}</div>}
//       <input
//         type="search"
//         placeholder="Search by title or author..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <br></br>
//       <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
//         {isFormVisible ? 'Cancel' : 'Add Book'}
//       </button>
//       <br></br>

//       {isFormVisible && (
//         <form onSubmit={handleSubmit}>
//           <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
//           <input type="text" placeholder="Author" value={author} onChange={(e) => setAuthor(e.target.value)} required />
//           <input type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} required />
//           <select value={category} onChange={(e) => setCategory(e.target.value)} required>
//             <option value="">Select Category</option>
//             <option value="Fiction">Fiction</option>
//             <option value="Non-Fiction">Non-Fiction</option>
//           </select>
//           <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
//             <option value="">Select Discount Range</option>
//             {discountOptions.map((option) => (
//               <option key={option.value} value={option.value}>
//                 {option.label}
//               </option>
//             ))}
//           </select>
//           <label className="label">Upload eBook PDF</label>
//           <input type="file" accept="application/pdf" onChange={handleFileChange} />
//           <label className="label">Upload eBook Cover</label>
//           <input type="file" accept="image/*" onChange={handleImageChange} />
//           <label className="label">Upload Author Biography Image</label>
//           <input type="file" accept="image/*" onChange={handleBiographyImageChange} />
//           <button type="submit">{editingBookId ? 'Update Book' : 'Add Book'}</button>
//         </form>
//       )}

//       <div className="book-list">
//         {paginatedBooks.map((book) => (
//           <div key={book._id} className="book-item">
//             {book.imagePath && (
//               <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
//             )}
//             <h3>{book.title}</h3>
//             <p>Author: {book.author}</p>
//             <p>Price: KES {book.price}</p>
//             <p>Category: {book.category}</p>
//             {book.discountRange && <p>Discount: {book.discountRange * 100}%</p>}
//             <button onClick={() => handleEdit(book)}>Edit</button>
//             <button onClick={() => handleDelete(book._id)}>Delete</button>
//             <button onClick={() => handleShareLink(book)}>Copy Link</button>
//           </div>
//         ))}
//       </div>

//       <div className="pagination">
//         {Array.from({ length: totalPages }, (_, i) => (
//           <button
//             key={i + 1}
//             onClick={() => setCurrentPage(i + 1)}
//             className={currentPage === i + 1 ? 'active' : ''}
//           >
//             {i + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddBookForm;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './AddBookForm.css';

// const DisclaimerPopup = ({ onClose }) => (
//   <div className="disclaimer-popup">
//     <div className="disclaimer-content">
//       <p>Disclaimer: Please ensure all details are correct before submitting the book.</p>
//       <button onClick={onClose}>I Understand</button>
//     </div>
//   </div>
// );

// const AddBookForm = () => {
//   const [title, setTitle] = useState('');
//   const [author, setAuthor] = useState('');
//   const [price, setPrice] = useState('');
//   const [category, setCategory] = useState('');
//   const [ebook, setEbook] = useState(null);
//   const [image, setImage] = useState(null);
//   const [biographyImage, setBiographyImage] = useState(null);
//   const [discountRange, setDiscountRange] = useState('');
//   const [notification, setNotification] = useState('');
//   const [books, setBooks] = useState([]);
//   const [editingBookId, setEditingBookId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [showDisclaimer, setShowDisclaimer] = useState(true);

//   const booksPerPage = 8;

//   const discountOptions = [
//     { label: '5-10%', value: 0.10 },
//     { label: '10-15%', value: 0.15 },
//     { label: '15-20%', value: 0.20 },
//     { label: '20-30%', value: 0.30 },
//   ];

//   useEffect(() => {
//     const fetchBooks = async () => {
//       try {
//         const response = await axios.get('http://195.35.48.252:4000/api/books');
//         setBooks(response.data);
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };

//     fetchBooks();
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const minPrice = price - (price * (discountRange || 0));
//     if (price < minPrice) {
//       setNotification(`Price cannot go below the selected discount threshold.`);
//       return;
//     }

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('author', author);
//     formData.append('price', price);
//     formData.append('category', category);
//     formData.append('discountRange', discountRange);
//     if (ebook) formData.append('ebook', ebook);
//     if (image) formData.append('image', image);
//     if (biographyImage) formData.append('biographyImage', biographyImage);

//     try {
//       let response;
//       if (editingBookId) {
//         response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) =>
//           prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
//         );
//       } else {
//         response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) => [...prevBooks, response.data]);
//       }
//       setNotification('Book added/updated successfully!');
//       setTitle('');
//       setAuthor('');
//       setPrice('');
//       setCategory('');
//       setDiscountRange('');
//       setEbook(null);
//       setImage(null);
//       setBiographyImage(null);
//       setEditingBookId(null);
//       setIsFormVisible(false);
//     } catch (error) {
//       setNotification('Error adding/updating book. Please try again.');
//     }
//   };

//   const handleFileChange = (e) => setEbook(e.target.files[0]);
//   const handleImageChange = (e) => setImage(e.target.files[0]);
//   const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

//   const handleDelete = async (bookId) => {
//     try {
//       await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
//       setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
//       setNotification('Book deleted successfully!');
//     } catch (error) {
//       setNotification('Error deleting book. Please try again.');
//     }
//   };

//   const handleEdit = (book) => {
//     setTitle(book.title);
//     setAuthor(book.author);
//     setPrice(book.price);
//     setCategory(book.category);
//     setDiscountRange(book.discountRange);
//     setEditingBookId(book._id);
//     setIsFormVisible(true);
//   };

//   const handleShareLink = (book) => {
//     const shareableLink = `http://195.35.48.252:3000/book/${book._id}`;
//     alert(`Share this link: ${shareableLink}`);
//   };

//   const filteredBooks = books.filter((book) =>
//     book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     book.author.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
//   const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

//   return (
//     <div className="add-book-form-container">
//       {showDisclaimer && <DisclaimerPopup onClose={() => setShowDisclaimer(false)} />}
//       {notification && <div className="notification">{notification}</div>}
//       <input
//         type="search"
//         placeholder="Search by title or author..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <br />
//       <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
//         {isFormVisible ? 'Cancel' : 'Add Book'}
//       </button>
//       <br />

//       {isFormVisible && (
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             placeholder="Title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//           />
//           <input
//             type="text"
//             placeholder="Author"
//             value={author}
//             onChange={(e) => setAuthor(e.target.value)}
//             required
//           />
//           <input
//             type="number"
//             placeholder="Price"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             required
//           />
//           <select value={category} onChange={(e) => setCategory(e.target.value)} required>
//             <option value="">Select Category</option>
//             <option value="Fiction">Fiction</option>
//             <option value="Non-Fiction">Non-Fiction</option>
           
//             {/* Add more categories as needed */}
//           </select>
//           <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
//             <option value="">Select Discount Range</option>
//             {discountOptions.map((option) => (
//               <option key={option.value} value={option.value}>{option.label}</option>
//             ))}
//           </select>
//           <label className="label">Upload eBook PDF</label>
//           <input type="file" onChange={handleFileChange} accept=".pdf" required />
//           <label className="label">Upload eBook Cover</label>
//           <input type="file" onChange={handleImageChange} accept="image/*" />
//           <label className="label">Upload Author Biography Image</label>
//           <input type="file" onChange={handleBiographyImageChange} accept="image/*" />
//           <button type="submit" className="submit-button">Submit</button>
//         </form>
//       )}

//       <div className="book-list">
//         {paginatedBooks.map((book) => (
//           <div key={book._id} className="book-item">
//             {book.imagePath && (
//               <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
//             )}
//             <h3>{book.title}</h3>
//             <p>Author: {book.author}</p>
//             <p>Price: KES {book.price}</p>
//             <p>Category: {book.category}</p>
//             {book.discountRange && <p>Discount: {book.discountRange * 100}%</p>}
//             <button onClick={() => handleEdit(book)}>Edit</button>
//             <button onClick={() => handleDelete(book._id)}>Delete</button>
//             <button onClick={() => handleShareLink(book)}>Copy Link</button>
//           </div>
//         ))}
//       </div>

//       <div className="pagination">
//         {Array.from({ length: totalPages }, (_, i) => (
//           <button
//             key={i + 1}
//             onClick={() => setCurrentPage(i + 1)}
//             className={currentPage === i + 1 ? 'active' : ''}
//           >
//             {i + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddBookForm;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './AddBookForm.css';

// const DisclaimerPopup = ({ onClose }) => (
//   <div className="disclaimer-popup">
//     <div className="disclaimer-content">
//       <p>Disclaimer: Please ensure all details are correct before submitting the book.</p>
//       <button onClick={onClose}>I Understand</button>
//     </div>
//   </div>
// );

// const AddBookForm = () => {
//   const [title, setTitle] = useState('');
//   const [author, setAuthor] = useState('');
//   const [price, setPrice] = useState(''); // USD price input
//   const [category, setCategory] = useState('');
//   const [ebook, setEbook] = useState(null);
//   const [image, setImage] = useState(null);
//   const [biographyImage, setBiographyImage] = useState(null);
//   const [discountRange, setDiscountRange] = useState('');
//   const [notification, setNotification] = useState('');
//   const [books, setBooks] = useState([]);
//   const [editingBookId, setEditingBookId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [showDisclaimer, setShowDisclaimer] = useState(true);

//   const booksPerPage = 8;

//   const discountOptions = [
//     { label: '5-10%', value: 0.10 },
//     { label: '10-15%', value: 0.15 },
//     { label: '15-20%', value: 0.20 },
//     { label: '20-30%', value: 0.30 },
//   ];

//   useEffect(() => {
//     const fetchBooks = async () => {
//       try {
//         const response = await axios.get('http://195.35.48.252:4000/api/books');
//         setBooks(response.data);
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };

//     fetchBooks();
//   }, []);

//   // Convert USD to Fanon (1 USD = 100 Fanon)
//   const convertUsdToFanon = (usdPrice) => {
//     return usdPrice * 100;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const minPrice = price - (price * (discountRange || 0));
//     if (price < minPrice) {
//       setNotification(`Price cannot go below the selected discount threshold.`);
//       return;
//     }

//     // Convert the price to Fanon before sending it to the server
//     const priceInFanon = convertUsdToFanon(price);

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('author', author);
//     formData.append('price', priceInFanon); // Store price in Fanon
//     formData.append('category', category);
//     formData.append('discountRange', discountRange);
//     if (ebook) formData.append('ebook', ebook);
//     if (image) formData.append('image', image);
//     if (biographyImage) formData.append('biographyImage', biographyImage);

//     try {
//       let response;
//       if (editingBookId) {
//         response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) =>
//           prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
//         );
//       } else {
//         response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) => [...prevBooks, response.data]);
//       }
//       setNotification('Book added/updated successfully!');
//       setTitle('');
//       setAuthor('');
//       setPrice('');
//       setCategory('');
//       setDiscountRange('');
//       setEbook(null);
//       setImage(null);
//       setBiographyImage(null);
//       setEditingBookId(null);
//       setIsFormVisible(false);
//     } catch (error) {
//       setNotification('Error adding/updating book. Please try again.');
//     }
//   };

//   const handleFileChange = (e) => setEbook(e.target.files[0]);
//   const handleImageChange = (e) => setImage(e.target.files[0]);
//   const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

//   const handleDelete = async (bookId) => {
//     try {
//       await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
//       setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
//       setNotification('Book deleted successfully!');
//     } catch (error) {
//       setNotification('Error deleting book. Please try again.');
//     }
//   };

//   const handleEdit = (book) => {
//     setTitle(book.title);
//     setAuthor(book.author);
//     setPrice(book.price); // USD price
//     setCategory(book.category);
//     setDiscountRange(book.discountRange);
//     setEditingBookId(book._id);
//     setIsFormVisible(true);
//   };

//   const handleShareLink = (book) => {
//     const shareableLink = `http://195.35.48.252:3000/book/${book._id}`;
//     alert(`Share this link: ${shareableLink}`);
//   };

//   const filteredBooks = books.filter((book) =>
//     book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     book.author.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
//   const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

//   return (
//     <div className="add-book-form-container">
//       {showDisclaimer && <DisclaimerPopup onClose={() => setShowDisclaimer(false)} />}
//       {notification && <div className="notification">{notification}</div>}
//       <input
//         type="search"
//         placeholder="Search by title or author..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <br />
//       <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
//         {isFormVisible ? 'Cancel' : 'Add Book'}
//       </button>
//       <br />

//       {isFormVisible && (
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             placeholder="Title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//           />
//           <input
//             type="text"
//             placeholder="Author"
//             value={author}
//             onChange={(e) => setAuthor(e.target.value)}
//             required
//           />
//           <input
//             type="number"
//             placeholder="Price (USD)"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             required
//           />
//           <select value={category} onChange={(e) => setCategory(e.target.value)} required>
//             <option value="">Select Category</option>
//             <option value="Fiction">Fiction</option>
//             <option value="Non-Fiction">Non-Fiction</option>
//           </select>
//           <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
//             <option value="">Select Discount Range</option>
//             {discountOptions.map((option) => (
//               <option key={option.value} value={option.value}>{option.label}</option>
//             ))}
//           </select>
//           <label className="label">Upload eBook PDF</label>
//           <input type="file" onChange={handleFileChange} accept=".pdf" required />
//           <label className="label">Upload eBook Cover</label>
//           <input type="file" onChange={handleImageChange} accept="image/*" />
//           <label className="label">Upload Author Biography Image</label>
//           <input type="file" onChange={handleBiographyImageChange} accept="image/*" />
//           <button type="submit" className="submit-button">Submit</button>
//         </form>
//       )}

//       <div className="book-list">
//         {paginatedBooks.map((book) => (
//           <div key={book._id} className="book-item">
//             {book.imagePath && (
//               <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
//             )}
//             <h3>{book.title}</h3>
//             <p>Author: {book.author}</p>
//             {/* Display the price in Fanon */}
//             <p>Price: {convertUsdToFanon(book.price)} Fanon</p> {/* Display Fanon price */}
//             <p>Category: {book.category}</p>
//             {book.discountRange && <p>Discount: {book.discountRange * 100}%</p>}
//             <button onClick={() => handleEdit(book)}>Edit</button>
//             <button onClick={() => handleDelete(book._id)}>Delete</button>
//             <button onClick={() => handleShareLink(book)}>Copy Link</button>
//           </div>
//         ))}
//       </div>

//       <div className="pagination">
//         {Array.from({ length: totalPages }, (_, i) => (
//           <button
//             key={i + 1}
//             onClick={() => setCurrentPage(i + 1)}
//             className={currentPage === i + 1 ? 'active' : ''}
//           >
//             {i + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddBookForm;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './AddBookForm.css';

// const DisclaimerPopup = ({ onClose }) => (
//   <div className="disclaimer-popup">
//     <div className="disclaimer-content">
//       <p>Disclaimer: Please ensure all details are correct before submitting the book.</p>
//       <button onClick={onClose}>I Understand</button>
//     </div>
//   </div>
// );

// const AddBookForm = () => {
//   const [title, setTitle] = useState('');
//   const [author, setAuthor] = useState('');
//   const [price, setPrice] = useState(''); // USD price input
//   const [category, setCategory] = useState('');
//   const [ebook, setEbook] = useState(null);
//   const [image, setImage] = useState(null);
//   const [biographyImage, setBiographyImage] = useState(null);
//   const [discountRange, setDiscountRange] = useState('');
//   const [notification, setNotification] = useState('');
//   const [books, setBooks] = useState([]);
//   const [editingBookId, setEditingBookId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [showDisclaimer, setShowDisclaimer] = useState(true);

//   const booksPerPage = 8;

//   const discountOptions = [
//     { label: '5-10%', value: 0.10 },
//     { label: '10-15%', value: 0.15 },
//     { label: '15-20%', value: 0.20 },
//     { label: '20-30%', value: 0.30 },
//   ];

//   useEffect(() => {
//     const fetchBooks = async () => {
//       try {
//         const response = await axios.get('http://195.35.48.252:4000/api/books');
//         setBooks(response.data);
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };

//     fetchBooks();
//   }, []);

//   // Convert USD to Fanon (1 USD = 100 Fanon)
//   const convertUsdToFanon = (usdPrice) => {
//     return usdPrice * 100;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const minPrice = price - (price * (discountRange || 0));
//     if (price < minPrice) {
//       setNotification(`Price cannot go below the selected discount threshold.`);
//       return;
//     }

//     // Convert the price to Fanon before sending it to the server
//     const priceInFanon = convertUsdToFanon(price);

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('author', author);
//     formData.append('price', priceInFanon); // Store price in Fanon
//     formData.append('category', category);
//     formData.append('discountRange', discountRange);
//     if (ebook) formData.append('ebook', ebook);
//     if (image) formData.append('image', image);
//     if (biographyImage) formData.append('biographyImage', biographyImage);

//     try {
//       let response;
//       if (editingBookId) {
//         response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) =>
//           prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
//         );
//       } else {
//         response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) => [...prevBooks, response.data]);
//       }
//       setNotification('Book added/updated successfully!');
//       setTitle('');
//       setAuthor('');
//       setPrice('');
//       setCategory('');
//       setDiscountRange('');
//       setEbook(null);
//       setImage(null);
//       setBiographyImage(null);
//       setEditingBookId(null);
//       setIsFormVisible(false);
//     } catch (error) {
//       setNotification('Error adding/updating book. Please try again.');
//     }
//   };

//   const handleFileChange = (e) => setEbook(e.target.files[0]);
//   const handleImageChange = (e) => setImage(e.target.files[0]);
//   const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

//   const handleDelete = async (bookId) => {
//     try {
//       await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
//       setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
//       setNotification('Book deleted successfully!');
//     } catch (error) {
//       setNotification('Error deleting book. Please try again.');
//     }
//   };

//   const handleEdit = (book) => {
//     setTitle(book.title);
//     setAuthor(book.author);
//     // Convert price from Fanon to USD for editing
//     const priceInUsd = book.price / 100;
//     setPrice(priceInUsd); // USD price
//     setCategory(book.category);
//     setDiscountRange(book.discountRange);
//     setEditingBookId(book._id);
//     setIsFormVisible(true);
//   };

//   const handleShareLink = (book) => {
//     const shareableLink = `http://195.35.48.252:3000/book/${book._id}`;
//     alert(`Share this link: ${shareableLink}`);
//   };

//   const filteredBooks = books.filter((book) =>
//     book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     book.author.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
//   const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

//   return (
//     <div className="add-book-form-container">
//       {showDisclaimer && <DisclaimerPopup onClose={() => setShowDisclaimer(false)} />}
//       {notification && <div className="notification">{notification}</div>}
//       <input
//         type="search"
//         placeholder="Search by title or author..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <br />
//       <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
//         {isFormVisible ? 'Cancel' : 'Add Book'}
//       </button>
//       <br />

//       {isFormVisible && (
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             placeholder="Title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//           />
//           <input
//             type="text"
//             placeholder="Author"
//             value={author}
//             onChange={(e) => setAuthor(e.target.value)}
//             required
//           />
//           <input
//             type="number"
//             placeholder="Price (USD)"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             required
//           />
//           <select value={category} onChange={(e) => setCategory(e.target.value)} required>
//             <option value="">Select Category</option>
//             <option value="Fiction">Fiction</option>
//             <option value="Non-Fiction">Non-Fiction</option>
//           </select>
//           <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
//             <option value="">Select Discount Range</option>
//             {discountOptions.map((option) => (
//               <option key={option.value} value={option.value}>{option.label}</option>
//             ))}
//           </select>
//           <label className="label">Upload eBook PDF</label>
//           <input type="file" onChange={handleFileChange} accept=".pdf" required />
//           <label className="label">Upload eBook Cover</label>
//           <input type="file" onChange={handleImageChange} accept="image/*" />
//           <label className="label">Upload Author Biography Image</label>
//           <input type="file" onChange={handleBiographyImageChange} accept="image/*" />
//           <button type="submit" className="submit-button">Submit</button>
//         </form>
//       )}

//       <div className="book-list">
//         {paginatedBooks.map((book) => (
//           <div key={book._id} className="book-item">
//             {book.imagePath && (
//               <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
//             )}
//             <h3>{book.title}</h3>
//             <p>Author: {book.author}</p>
//             {/* Display the price in Fanon */}
//             <p>Price: {convertUsdToFanon(book.price)} Fanon</p>
//             <p>Category: {book.category}</p>
//             <p>Discount Range: {book.discountRange}</p>
//             <button onClick={() => handleEdit(book)}>Edit</button>
//             <button onClick={() => handleDelete(book._id)}>Delete</button>
//             <button onClick={() => handleShareLink(book)}>Share Link</button>
//           </div>
//         ))}
//       </div>

//       <div className="pagination">
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button
//             key={index}
//             onClick={() => setCurrentPage(index + 1)}
//             className={currentPage === index + 1 ? 'active' : ''}
//           >
//             {index + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddBookForm;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './AddBookForm.css';

// const DisclaimerPopup = ({ onClose }) => (
//   <div className="disclaimer-popup">
//     <div className="disclaimer-content">
//       <p>Disclaimer: Please ensure all details are correct before submitting the book.</p>
//       <button onClick={onClose}>I Understand</button>
//     </div>
//   </div>
// );

// const AddBookForm = () => {
//   const [title, setTitle] = useState('');
//   const [author, setAuthor] = useState('');
//   const [price, setPrice] = useState(''); // USD price input
//   const [category, setCategory] = useState('');
//   const [ebook, setEbook] = useState(null);
//   const [image, setImage] = useState(null);
//   const [biographyImage, setBiographyImage] = useState(null);
//   const [discountRange, setDiscountRange] = useState('');
//   const [notification, setNotification] = useState('');
//   const [books, setBooks] = useState([]);
//   const [editingBookId, setEditingBookId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [showDisclaimer, setShowDisclaimer] = useState(true);

//   const booksPerPage = 8;

//   const discountOptions = [
//     { label: '5-10%', value: 0.10 },
//     { label: '10-15%', value: 0.15 },
//     { label: '15-20%', value: 0.20 },
//     { label: '20-30%', value: 0.30 },
//   ];

//   useEffect(() => {
//     const fetchBooks = async () => {
//       try {
//         const response = await axios.get('http://195.35.48.252:4000/api/books');
//         setBooks(response.data);
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };

//     fetchBooks();
//   }, []);

//   // Convert USD to Fanon (1 USD = 100 Fanon)
//   const convertUsdToFanon = (usdPrice) => {
//     return usdPrice * 100;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const minPrice = price - (price * (discountRange || 0));
//     if (price < minPrice) {
//       setNotification(`Price cannot go below the selected discount threshold.`);
//       return;
//     }

//     // Convert the price to Fanon before sending it to the server
//     const priceInFanon = convertUsdToFanon(price);

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('author', author);
//     formData.append('price', priceInFanon); // Store price in Fanon
//     formData.append('category', category);
//     formData.append('discountRange', discountRange);
//     if (ebook) formData.append('ebook', ebook);
//     if (image) formData.append('image', image);
//     if (biographyImage) formData.append('biographyImage', biographyImage);

//     try {
//       let response;
//       if (editingBookId) {
//         response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) =>
//           prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
//         );
//       } else {
//         response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) => [...prevBooks, response.data]);
//       }
//       setNotification('Book added/updated successfully!');
//       setTitle('');
//       setAuthor('');
//       setPrice('');
//       setCategory('');
//       setDiscountRange('');
//       setEbook(null);
//       setImage(null);
//       setBiographyImage(null);
//       setEditingBookId(null);
//       setIsFormVisible(false);
//     } catch (error) {
//       setNotification('Error adding/updating book. Please try again.');
//     }
//   };

//   const handleFileChange = (e) => setEbook(e.target.files[0]);
//   const handleImageChange = (e) => setImage(e.target.files[0]);
//   const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

//   const handleDelete = async (bookId) => {
//     try {
//       await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
//       setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
//       setNotification('Book deleted successfully!');
//     } catch (error) {
//       setNotification('Error deleting book. Please try again.');
//     }
//   };

//   const handleEdit = (book) => {
//     setTitle(book.title);
//     setAuthor(book.author);
//     // Convert price from Fanon to USD for editing
//     const priceInUsd = book.price / 100; // Assuming book.price is stored in Fanon
//     setPrice(priceInUsd); // USD price
//     setCategory(book.category);
//     setDiscountRange(book.discountRange);
//     setEditingBookId(book._id);
//     setIsFormVisible(true);
//   };

//   const handleShareLink = (book) => {
//     const shareableLink = `http://195.35.48.252:3000/book/${book._id}`;
//     alert(`Share this link: ${shareableLink}`);
//   };

//   const filteredBooks = books.filter((book) =>
//     book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     book.author.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
//   const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

//   return (
//     <div className="add-book-form-container">
//       {showDisclaimer && <DisclaimerPopup onClose={() => setShowDisclaimer(false)} />}
//       {notification && <div className="notification">{notification}</div>}
//       <input
//         type="search"
//         placeholder="Search by title or author..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <br />
//       <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
//         {isFormVisible ? 'Cancel' : 'Add Book'}
//       </button>
//       <br />

//       {isFormVisible && (
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             placeholder="Title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//           />
//           <input
//             type="text"
//             placeholder="Author"
//             value={author}
//             onChange={(e) => setAuthor(e.target.value)}
//             required
//           />
//           <input
//             type="number"
//             placeholder="Price (USD)"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             required
//           />
//           <select value={category} onChange={(e) => setCategory(e.target.value)} required>
//             <option value="">Select Category</option>
//             <option value="Fiction">Fiction</option>
//             <option value="Non-Fiction">Non-Fiction</option>
//           </select>
//           <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
//             <option value="">Select Discount Range</option>
//             {discountOptions.map((option) => (
//               <option key={option.value} value={option.value}>{option.label}</option>
//             ))}
//           </select>
//           <label className="label">Upload eBook PDF</label>
//           <input type="file" onChange={handleFileChange} accept=".pdf" required />
//           <label className="label">Upload eBook Cover</label>
//           <input type="file" onChange={handleImageChange} accept="image/*" />
//           <label className="label">Upload Author Biography Image</label>
//           <input type="file" onChange={handleBiographyImageChange} accept="image/*" />
//           <button type="submit" className="submit-button">Submit</button>
//         </form>
//       )}

//       <div className="book-list">
//         {paginatedBooks.map((book) => (
//           <div key={book._id} className="book-item">
//             {book.imagePath && (
//               <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
//             )}
//             <h3>{book.title}</h3>
//             <p>Author: {book.author}</p>
//             <p>Price: {(book.price / 100).toFixed(2)} USD / {book.price} Fanon</p>
//             <p>Category: {book.category}</p>
//             {/* <p>Discount: {book.discountRange * 100}%</p> */}
//             <button onClick={() => handleEdit(book)}>Edit</button>
//             <button onClick={() => handleDelete(book._id)}>Delete</button>
//             <button onClick={() => handleShareLink(book)}>Share</button>
//           </div>
//         ))}
//       </div>

//       <div className="pagination">
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button key={index} onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddBookForm;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './AddBookForm.css';

// const DisclaimerPopup = ({ onClose }) => (
//   <div className="disclaimer-popup">
//     <div className="disclaimer-content">
//       <p>Disclaimer: Please ensure all details are correct before submitting the book.</p>
//       <button onClick={onClose}>I Understand</button>
//     </div>
//   </div>
// );

// const AddBookForm = () => {
//   const [title, setTitle] = useState('');
//   const [author, setAuthor] = useState('');
//   const [price, setPrice] = useState(''); // USD price input
//   const [category, setCategory] = useState('');
//   const [ebook, setEbook] = useState(null);
//   const [image, setImage] = useState(null);
//   const [biographyImage, setBiographyImage] = useState(null);
//   const [discountRange, setDiscountRange] = useState('');
//   const [notification, setNotification] = useState('');
//   const [books, setBooks] = useState([]);
//   const [editingBookId, setEditingBookId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [showDisclaimer, setShowDisclaimer] = useState(true);

//   const booksPerPage = 8;

//   const discountOptions = [
//     { label: '5-10%', value: 0.10 },
//     { label: '10-15%', value: 0.15 },
//     { label: '15-20%', value: 0.20 },
//     { label: '20-30%', value: 0.30 },
//   ];

//   useEffect(() => {
//     const fetchBooks = async () => {
//       try {
//         const response = await axios.get('http://195.35.48.252:4000/api/books');
//         setBooks(response.data);
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };

//     fetchBooks();
//   }, []);

//   // Convert USD to Fanon (1 USD = 100 Fanon)
//   const convertUsdToFanon = (usdPrice) => {
//     return usdPrice * 100;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const minPrice = price - (price * (discountRange || 0));
//     if (price < minPrice) {
//       setNotification(`Price cannot go below the selected discount threshold.`);
//       return;
//     }

//     // Convert the price to Fanon before sending it to the server
//     const priceInFanon = convertUsdToFanon(price);

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('author', author);
//     formData.append('price', priceInFanon); // Store price in Fanon
//     formData.append('category', category);
//     formData.append('discountRange', discountRange);
//     if (ebook) formData.append('ebook', ebook);
//     if (image) formData.append('image', image);
//     if (biographyImage) formData.append('biographyImage', biographyImage);

//     try {
//       let response;
//       if (editingBookId) {
//         response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) =>
//           prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
//         );
//       } else {
//         response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) => [...prevBooks, response.data]);
//       }
//       setNotification('Book added/updated successfully!');
//       setTitle('');
//       setAuthor('');
//       setPrice('');
//       setCategory('');
//       setDiscountRange('');
//       setEbook(null);
//       setImage(null);
//       setBiographyImage(null);
//       setEditingBookId(null);
//       setIsFormVisible(false);
//     } catch (error) {
//       setNotification('Error adding/updating book. Please try again.');
//     }
//   };

//   const handleFileChange = (e) => setEbook(e.target.files[0]);
//   const handleImageChange = (e) => setImage(e.target.files[0]);
//   const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

//   const handleDelete = async (bookId) => {
//     try {
//       await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
//       setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
//       setNotification('Book deleted successfully!');
//     } catch (error) {
//       setNotification('Error deleting book. Please try again.');
//     }
//   };

//   const handleEdit = (book) => {
//     setTitle(book.title);
//     setAuthor(book.author);
//     // Convert price from Fanon to USD for editing
//     const priceInUsd = book.price / 100; // Assuming book.price is stored in Fanon
//     setPrice(priceInUsd); // USD price
//     setCategory(book.category);
//     setDiscountRange(book.discountRange);
//     setEditingBookId(book._id);
//     setIsFormVisible(true);
//   };

//   const handleShareLink = (book) => {
//     const shareableLink = `http://195.35.48.252:3000/book/${book._id}`;
//     alert(`Share this link: ${shareableLink}`);
//   };

//   const filteredBooks = books.filter((book) =>
//     book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     book.author.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
//   const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

//   return (
//     <div className="add-book-form-container">
//       {showDisclaimer && <DisclaimerPopup onClose={() => setShowDisclaimer(false)} />}
//       {notification && <div className="notification">{notification}</div>}
//       <input
//         type="search"
//         placeholder="Search by title or author..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <br />
//       <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
//         {isFormVisible ? 'Cancel' : 'Add Book'}
//       </button>
//       <br />

//       {isFormVisible && (
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             placeholder="Title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//           />
//           <input
//             type="text"
//             placeholder="Author"
//             value={author}
//             onChange={(e) => setAuthor(e.target.value)}
//             required
//           />
//           <input
//             type="number"
//             placeholder="Price (USD)"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             required
//           />
//           <select value={category} onChange={(e) => setCategory(e.target.value)} required>
//             <option value="">Select Category</option>
//             <option value="Fiction">Fiction</option>
//             <option value="Non-Fiction">Non-Fiction</option>
//           </select>
//           <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
//             <option value="">Select Discount Range</option>
//             {discountOptions.map((option) => (
//               <option key={option.value} value={option.value}>{option.label}</option>
//             ))}
//           </select>
//           <label className="label">Upload eBook PDF</label>
//           <input type="file" onChange={handleFileChange} accept=".pdf" required />
//           <label className="label">Upload eBook Cover</label>
//           <input type="file" onChange={handleImageChange} accept="image/*" />
//           <label className="label">Upload Author Biography Image</label>
//           <input type="file" onChange={handleBiographyImageChange} accept="image/*" />
//           <button type="submit" className="submit-button">Submit</button>
//         </form>
//       )}

//       <div className="book-list">
//         {paginatedBooks.map((book) => (
//           <div key={book._id} className="book-item">
//             {book.imagePath && (
//               <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
//             )}
//             <h3>{book.title}</h3>
//             <p>Author: {book.author}</p>
//             <p>Price: {(book.price / 100).toFixed(2)} USD / {book.price} Fanon</p>
//             <p>Category: {book.category}</p>
//             <button onClick={() => handleEdit(book)}>Edit</button>
//             <button onClick={() => handleDelete(book._id)}>Delete</button>
//             <button onClick={() => handleShareLink(book)}>Share Link</button>
//           </div>
//         ))}
//       </div>

//       <div className="pagination">
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button
//             key={index}
//             onClick={() => setCurrentPage(index + 1)}
//             className={currentPage === index + 1 ? 'active' : ''}
//           >
//             {index + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AddBookForm;


// import React, { useState, useEffect } from 'react'; 
// import axios from 'axios';
// import './AddBookForm.css';

// const DisclaimerPopup = ({ onClose }) => (
//   <div className="disclaimer-popup">
//     <div className="disclaimer-content">
//       <p>Disclaimer: Please ensure all details are correct before submitting the book.</p>
//       <button onClick={onClose}>I Understand</button>
//     </div>
//   </div>
// );

// const AddBookForm = () => {
//   const [title, setTitle] = useState('');
//   const [author, setAuthor] = useState('');
//   const [price, setPrice] = useState(''); // USD price input
//   const [category, setCategory] = useState('');
//   const [ebook, setEbook] = useState(null);
//   const [image, setImage] = useState(null);
//   const [biographyImage, setBiographyImage] = useState(null);
//   const [discountRange, setDiscountRange] = useState('');
//   const [notification, setNotification] = useState('');
//   const [books, setBooks] = useState([]);
//   const [editingBookId, setEditingBookId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [showDisclaimer, setShowDisclaimer] = useState(true);

//   const booksPerPage = 8;

//   const discountOptions = [
//     { label: '5-10%', value: 0.10 },
//     { label: '10-15%', value: 0.15 },
//     { label: '15-20%', value: 0.20 },
//     { label: '20-30%', value: 0.30 },
//   ];

//   useEffect(() => {
//     const fetchBooks = async () => {
//       try {
//         const response = await axios.get('http://195.35.48.252:4000/api/books');
//         setBooks(response.data);
//       } catch (error) {
//         console.error('Error fetching books:', error);
//       }
//     };

//     fetchBooks();
//   }, []);

//   // Convert USD to Fanon (1 USD = 100 Fanon)
//   const convertUsdToFanon = (usdPrice) => {
//     return usdPrice * 100;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const minPrice = price - (price * (discountRange || 0));
//     if (price < minPrice) {
//       setNotification(`Price cannot go below the selected discount threshold.`);
//       return;
//     }

//     // Convert the price to Fanon before sending it to the server
//     const priceInFanon = convertUsdToFanon(price);

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('author', author);
//     formData.append('price', priceInFanon); // Store price in Fanon
//     formData.append('category', category);
//     formData.append('discountRange', discountRange);
//     if (ebook) formData.append('ebook', ebook);
//     if (image) formData.append('image', image);
//     if (biographyImage) formData.append('biographyImage', biographyImage);

//     try {
//       let response;
//       if (editingBookId) {
//         response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) =>
//           prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
//         );
//       } else {
//         response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
//           headers: { 'Content-Type': 'multipart/form-data' },
//         });
//         setBooks((prevBooks) => [...prevBooks, response.data]);
//       }
//       setNotification('Book added/updated successfully!');
//       setTitle('');
//       setAuthor('');
//       setPrice('');
//       setCategory('');
//       setDiscountRange('');
//       setEbook(null);
//       setImage(null);
//       setBiographyImage(null);
//       setEditingBookId(null);
//       setIsFormVisible(false);
//     } catch (error) {
//       setNotification('Error adding/updating book. Please try again.');
//     }
//   };

//   const handleFileChange = (e) => setEbook(e.target.files[0]);
//   const handleImageChange = (e) => setImage(e.target.files[0]);
//   const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

//   const handleDelete = async (bookId) => {
//     try {
//       await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
//       setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
//       setNotification('Book deleted successfully!');
//     } catch (error) {
//       setNotification('Error deleting book. Please try again.');
//     }
//   };

//   const handleEdit = (book) => {
//     setTitle(book.title);
//     setAuthor(book.author);
//     // Convert price from Fanon to USD for editing
//     const priceInUsd = book.price / 100; // Assuming book.price is stored in Fanon
//     setPrice(priceInUsd); // USD price
//     setCategory(book.category);
//     setDiscountRange(book.discountRange);
//     setEditingBookId(book._id);
//     setIsFormVisible(true);
//   };

//   const handleShareLink = (book) => {
//     const shareableLink = `http://195.35.48.252:4000/api/books/${book._id}`;
//     alert(`Share this link to download the eBook: ${shareableLink}`);
//   };

//   const filteredBooks = books.filter((book) =>
//     book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     book.author.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
//   const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

//   return (
//     <div className="add-book-form-container">
//       {showDisclaimer && <DisclaimerPopup onClose={() => setShowDisclaimer(false)} />}
//       {notification && <div className="notification">{notification}</div>}
//       <input
//         type="search"
//         placeholder="Search by title or author..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <br />
//       <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
//         {isFormVisible ? 'Cancel' : 'Add Book'}
//       </button>
//       <br />

//       {isFormVisible && (
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             placeholder="Title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//           />
//           <input
//             type="text"
//             placeholder="Author"
//             value={author}
//             onChange={(e) => setAuthor(e.target.value)}
//             required
//           />
//           <input
//             type="number"
//             placeholder="Price (USD)"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             required
//           />
//           <select value={category} onChange={(e) => setCategory(e.target.value)} required>
//             <option value="">Select Category</option>
//             <option value="Fiction">Fiction</option>
//             <option value="Non-Fiction">Non-Fiction</option>
//           </select>
//           <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
//             <option value="">Select Discount Range</option>
//             {discountOptions.map((option) => (
//               <option key={option.value} value={option.value}>{option.label}</option>
//             ))}
//           </select>
//           <label className="label">Upload eBook PDF</label>
//           <input type="file" onChange={handleFileChange} accept=".pdf" required />
//           <label className="label">Upload eBook Cover</label>
//           <input type="file" onChange={handleImageChange} accept="image/*" />
//           <label className="label">Upload Author Biography Image</label>
//           <input type="file" onChange={handleBiographyImageChange} accept="image/*" />
//           <button type="submit" className="submit-button">Submit</button>
//         </form>
//       )}

//       <div className="book-list">
//         {paginatedBooks.map((book) => (
//           <div key={book._id} className="book-item">
//             {book.imagePath && (
//               <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
//             )}
//             <h3>{book.title}</h3>
//             <p>Author: {book.author}</p>
//             <p>Price: {(book.price / 100).toFixed(2)} USD / {book.price} Fanon</p>
//             <p>Category: {book.category}</p>
//             <button onClick={() => handleEdit(book)}>Edit</button>
//             <button onClick={() => handleDelete(book._id)}>Delete</button>
//             <button onClick={() => handleShareLink(book)}>Share Link</button>
//           </div>
//         ))}
//       </div>

//       <div className="pagination">
//         <button disabled={currentPage === 1} onClick={() => setCurrentPage((page) => page - 1)}>Previous</button>
//         <span>{currentPage} of {totalPages}</span>
//         <button disabled={currentPage === totalPages} onClick={() => setCurrentPage((page) => page + 1)}>Next</button>
//       </div>
//     </div>
//   );
// };

// export default AddBookForm;






import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddBookForm.css';

const DisclaimerPopup = ({ onClose }) => (
  <div className="disclaimer-popup">
    <div className="disclaimer-content">
      <p>Disclaimer: Please ensure all details are correct before submitting the book.</p>
      <button onClick={onClose}>I Understand</button>
    </div>
  </div>
);

const AddBookForm = () => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [price, setPrice] = useState(''); // USD price input
  const [category, setCategory] = useState('');
  const [ebook, setEbook] = useState(null);
  const [image, setImage] = useState(null);
  const [biographyImage, setBiographyImage] = useState(null);
  const [discountRange, setDiscountRange] = useState('');
  const [notification, setNotification] = useState('');
  const [books, setBooks] = useState([]);
  const [editingBookId, setEditingBookId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const booksPerPage = 8;

  const discountOptions = [
    { label: '5-10%', value: 0.10 },
    { label: '10-15%', value: 0.15 },
    { label: '15-20%', value: 0.20 },
    { label: '20-30%', value: 0.30 },
  ];

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get('http://195.35.48.252:4000/api/books');
        setBooks(response.data);
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };

    fetchBooks();
  }, []);

  const convertUsdToFanon = (usdPrice) => usdPrice * 100;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const minPrice = price - (price * (discountRange || 0));
    if (price < minPrice) {
      setNotification(`Price cannot go below the selected discount threshold.`);
      return;
    }

    const priceInFanon = convertUsdToFanon(price);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('author', author);
    formData.append('price', priceInFanon); // Store price in Fanon
    formData.append('category', category);
    formData.append('discountRange', discountRange);
    if (ebook) formData.append('ebook', ebook);
    if (image) formData.append('image', image);
    if (biographyImage) formData.append('biographyImage', biographyImage);

    try {
      let response;
      if (editingBookId) {
        response = await axios.put(`http://195.35.48.252:4000/api/books/${editingBookId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setBooks((prevBooks) =>
          prevBooks.map((book) => (book._id === editingBookId ? response.data : book))
        );
      } else {
        response = await axios.post('http://195.35.48.252:4000/api/books', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setBooks((prevBooks) => [...prevBooks, response.data]);
      }
      setNotification('Book added/updated successfully!');
      setTitle('');
      setAuthor('');
      setPrice('');
      setCategory('');
      setDiscountRange('');
      setEbook(null);
      setImage(null);
      setBiographyImage(null);
      setEditingBookId(null);
      setIsFormVisible(false);
    } catch (error) {
      setNotification('Error adding/updating book. Please try again.');
    }
  };

  const handleFileChange = (e) => setEbook(e.target.files[0]);
  const handleImageChange = (e) => setImage(e.target.files[0]);
  const handleBiographyImageChange = (e) => setBiographyImage(e.target.files[0]);

  const handleDelete = async (bookId) => {
    try {
      await axios.delete(`http://195.35.48.252:4000/api/books/${bookId}`);
      setBooks((prevBooks) => prevBooks.filter((book) => book._id !== bookId));
      setNotification('Book deleted successfully!');
    } catch (error) {
      setNotification('Error deleting book. Please try again.');
    }
  };

  const handleEdit = (book) => {
    setTitle(book.title);
    setAuthor(book.author);
    const priceInUsd = book.price / 100;
    setPrice(priceInUsd); // USD price
    setCategory(book.category);
    setDiscountRange(book.discountRange);
    setEditingBookId(book._id);
    setIsFormVisible(true);
  };

  const handleShareLink = (book) => {
    const shareableLink = `http://195.35.48.252:4000/api/books/download/${book._id}`;
    alert(`Share this link to download the eBook: ${shareableLink}`);
  };

  // const handleShareLink = (book) => {
  //   const shareableLink = `http://195.35.48.252:3000/download?links=${encodeURIComponent(JSON.stringify([{ title: book.title, link: `http://195.35.48.252:4000/api/books/download/${book._id}` }]))}`;
  //   window.location.href = shareableLink;  // Redirect to the DownloadPage with the link
  // };
  

  const filteredBooks = books.filter((book) =>
    book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    book.author.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
  const paginatedBooks = filteredBooks.slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage);

  return (
    <div className="add-book-form-container">
      {showDisclaimer && <DisclaimerPopup onClose={() => setShowDisclaimer(false)} />}
      {notification && <div className="notification">{notification}</div>}
      <input
        type="search"
        placeholder="Search by title or author..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <br />
      <button onClick={() => setIsFormVisible(!isFormVisible)} className="add-book-button">
        {isFormVisible ? 'Cancel' : 'Add Book'}
      </button>
      <br />

      {isFormVisible && (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
          <input
            type="number"
            placeholder="Price (USD)"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
          <select value={category} onChange={(e) => setCategory(e.target.value)} required>
            <option value="">Select Category</option>
            <option value="Fiction">Fiction</option>
            <option value="Non-Fiction">Non-Fiction</option>
          </select>
          <select value={discountRange} onChange={(e) => setDiscountRange(e.target.value)} required>
            <option value="">Select Discount Range</option>
            {discountOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
          <label className="label">Upload eBook PDF</label>
          <input type="file" onChange={handleFileChange} accept=".pdf" required />
          <label className="label">Upload eBook Cover</label>
          <input type="file" onChange={handleImageChange} accept="image/*" />
          <label className="label">Upload Author Biography Image</label>
          <input type="file" onChange={handleBiographyImageChange} accept="image/*" />
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}

      <div className="book-list">
        {paginatedBooks.map((book) => (
          <div key={book._id} className="book-item">
            {book.imagePath && (
              <img src={`http://195.35.48.252:4000/${book.imagePath}`} alt={`${book.title} cover`} className="book-image" />
            )}
            <h3>{book.title}</h3>
            <p>Author: {book.author}</p>
            <p>Price: {(book.price / 100).toFixed(2)} USD / {book.price} Fanon</p>
            <p>Category: {book.category}</p>
            {/* <p>Discount Range: {discountOptions.find(option => option.value == book.discountRange)?.label || 'N/A'}</p> */}
            <button onClick={() => handleEdit(book)} className="edit-button">Edit</button>
            <button onClick={() => handleDelete(book._id)} className="delete-button">Delete</button>
            <button onClick={() => handleShareLink(book)} className="share-link-button">SharedLink</button>
          </div>
        ))}
      </div>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={page === currentPage ? 'active' : ''}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AddBookForm;





