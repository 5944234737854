

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ArtList.css'; // Import your CSS file for styling
import { io } from 'socket.io-client'; // Import socket.io client


const ArtList = () => {
  const [arts, setArts] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [selectedImage, setSelectedImage] = useState(null); // State for selected art image
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [artsPerPage] = useState(4); // Number of arts per page

  useEffect(() => {
    // Fetch arts initially
    const fetchArts = async () => {
      try {
        const response = await axios.get('http://195.35.48.252:4000/api/arts');
        setArts(response.data);
      } catch (error) {
        console.error('Error fetching arts:', error);
      }
    };

    fetchArts();

    // Set up socket connection for real-time updates
    const socket = io('http://195.35.48.252:4000'); // Connect to server

    // Handle any real-time updates here (if needed)

    return () => {
      socket.disconnect(); // Cleanup socket on component unmount
    };
  }, []);

  // Pagination logic
  const indexOfLastArt = currentPage * artsPerPage;
  const indexOfFirstArt = indexOfLastArt - artsPerPage;
  const currentArts = arts.slice(indexOfFirstArt, indexOfLastArt); // Get current arts

  // Calculate total number of pages
  const totalPages = Math.ceil(arts.length / artsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Filter arts based on search query
  const filteredArts = currentArts.filter(
    (art) =>
      art.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      art.artist.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to handle image click
  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath); // Set the selected image for the modal
  };

  return (
    <div className="art-list-container">
      <h2 className="art-list-title">ART COLLECTION</h2>

      {/* Search Bar */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by title or artist..."
          className="search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {filteredArts.length === 0 ? (
        <p>No arts available</p>
      ) : (
        <ul className="art-list">
          {filteredArts.map((art) => (
            <li className="art-item" key={art._id}>
              <div className="art-details">
                {/* Display the art image */}
                {art.imagePath && (
                  <img
                    src={`http://195.35.48.252:4000${art.imagePath}`}
                    alt={art.title} // Directly use the title for alt text
                    className="art-image"
                    onClick={() => handleImageClick(art.imagePath)} // Add click handler
                  />
                )}

                <h3>{art.title}</h3>
                <p>Artist: {art.artist}</p>
                <p>Price: {art.price} units</p>
                <button className="purchase-btn">Purchase</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Pagination */}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? 'active-page' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Image Popup Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}> {/* Close modal on click */}
          <div className="image-modal-content">
            <span className="close-btn" onClick={() => setSelectedImage(null)}>&times;</span> {/* Close button */}
            <img src={`http://195.35.48.252:4000${selectedImage}`} alt="Art" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ArtList;
