

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './BookList.css';
import BookGraph from './BookGraph';
import { io } from 'socket.io-client';
import Cart from './Cart'; // Import Cart.js
import ebookImage from './images/fanon.png';

const Dashboard = ({ onLogout }) => {
  const username = localStorage.getItem('username');

  return (
    <div>
      <br />
      {username ? <p>Welcome, {username}!</p> : <p>Welcome!</p>}
      <button className='logout' onClick={onLogout}>Logout</button>
    </div>
  );
};

const BookList = () => {
  const navigate = useNavigate();
  const [books, setBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [booksPerPage] = useState(8);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [isBioImageOpen, setIsBioImageOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [addedToCart, setAddedToCart] = useState(new Set());
  const [showCart, setShowCart] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Fetch books and handle real-time price updates using socket.io
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get('http://195.35.48.252:4000/api/books');
        setBooks(response.data);
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };

    fetchBooks();

    const socket = io('http://195.35.48.252:4000');
    socket.on('bookPriceUpdate', (updatedBook) => {
      setBooks((prevBooks) =>
        prevBooks.map((book) => (book._id === updatedBook._id ? updatedBook : book))
      );
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Prevent the user from going back in browser history
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handleBeforeUnload);
    };
  }, []);

  // Handle user logout and clear token from localStorage
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/');
  };

  // Filter books by search query and selected category
  const filteredBooks = books.filter((book) => {
    const matchesSearch = book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      book.author.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory ? book.category === selectedCategory : true;
    return matchesSearch && matchesCategory;
  });

  // Pagination logic
  const totalPages = Math.ceil(filteredBooks.length / booksPerPage);
  const indexOfLastBook = currentPage * booksPerPage;
  const indexOfFirstBook = indexOfLastBook - booksPerPage;
  const currentBooks = filteredBooks.slice(indexOfFirstBook, indexOfLastBook);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Generate a random download link for the ebook
const generateDownloadLink = (bookId) => {
  // You can create a link pattern, or generate an actual link here
  return `http://195.35.48.252:4000/download/${bookId}?downloadId=${Math.random().toString(36).substring(2, 15)}`;
};

const handleEngagementAndAddToCart = async (book) => {
  if (addedToCart.has(book._id)) {
    return; // Prevent adding the same book to the cart again
  }

  try {
    const response = await axios.post(`http://195.35.48.252:4000/api/books/${book._id}/engagement`);
    const updatedBook = response.data;

    // Generate the download link for the book
    updatedBook.downloadLink = generateDownloadLink(updatedBook._id);

    setBooks((prevBooks) =>
      prevBooks.map((b) => (b._id === updatedBook._id ? updatedBook : b))
    );

    setCart((prevCart) => [...prevCart, updatedBook]);
    setAddedToCart((prev) => new Set(prev).add(book._id));
  } catch (error) {
    console.error('Error updating engagement and adding to cart:', error);
  }
};

  // Toggle the author bio display
  const toggleBio = (bookId) => {
    setSelectedBookId((prevBookId) => (prevBookId === bookId ? null : bookId));
  };

  const handleBioImageClick = (imagePath) => {
    setSelectedImage(imagePath); // Set the clicked biography image path
    setIsBioImageOpen(true);
  };

  const closeBioImageModal = () => {
    setIsBioImageOpen(false);
    setSelectedImage(null);
  };

  // Pop-up logic for book image
  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setSelectedImage(null);
  };

  // Handle checkout process and clear the cart after checkout
  const handleCheckout = () => {
    alert("Proceeding to checkout with the following books:\n" + cart.map(item => `${item.title} by ${item.author}`).join('\n'));
    setCart([]); // Clear the cart after checkout
  };

  return (
    <div>
      <div className="header">
        <h1>eBOOK LIST </h1>
        <button className="view-cart-btn" onClick={() => setShowCart(true)}>
          View Cart ({cart.length})
        </button>
      </div>
      {showCart ? (
        <Cart
          cart={cart}
          onCheckout={handleCheckout}
          onContinueShopping={() => setShowCart(false)}
          onRemoveFromCart={(id) => setCart(cart.filter(item => item._id !== id))}
        />
      ) : (
        <>
          <Dashboard onLogout={handleLogout} />
          <div className="book-list-container">
            <h2 className="book-list-title">BOOK COLLECTION</h2>

            <div className="search-container">
              <input
                type="text"
                placeholder="Search by title or author..."
                className="search-bar"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
              />
              <select
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  setCurrentPage(1);
                }}
                className="category-dropdown"
              >
                <option value="">All Categories</option>
                <option value="Fiction">Fiction</option>
                <option value="Non-Fiction">Non-Fiction</option>
              </select>
            </div>

            {currentBooks.length === 0 ? (
              <p>No books available</p>
            ) : (
              <ul className="book-list">
                {currentBooks.map((book) => (
                  <li className="book-item" key={book._id}>
                    <div className="book-details">
                    
                      {book.imagePath && (
                        <img
                          src={`http://195.35.48.252:4000/${book.imagePath}`}
                          alt={book.title}
                          className="book-image"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleImageClick(`http://195.35.48.252:4000/${book.imagePath}`)} // Open image pop-up on click
                        />
                        
                      )}
                      
                      <h3>{book.title}</h3>
                      <p>By: {book.author}</p>
                      <p>Category: {book.category}</p>
                      <img src={ebookImage} alt="eBook Cover" className="ebook-image" />
                      <p>Price:  {book.price} Fanon</p>
                      <p>Engagements: {book.engagements}</p>
                      <button
                        className="purchase-btn"
                        onClick={() => handleEngagementAndAddToCart(book)}
                        disabled={addedToCart.has(book._id)}
                      >
                        {addedToCart.has(book._id) ? 'Added to Cart' : 'Add to Cart'}
                      </button>
                      <button onClick={() => toggleBio(book._id)}>
                        {selectedBookId === book._id ? 'Hide Bio' : 'Show Bio'}
                      </button>

                      {selectedBookId === book._id && (
                        <div className="author-bio">
                          <h4>About the Author:</h4>
                          {book.biographyImagePath && (
                            <img
                              src={`http://195.35.48.252:4000/${book.biographyImagePath}`} // Use the biography image path
                              alt="Author"
                              className="author-image"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleBioImageClick(`http://195.35.48.252:4000/${book.biographyImagePath}`)} // Pass the biography image path
                            />
                          )}
                          <p>{book.authorBio}</p>
                        </div>
                      )}
                    </div>

                    <BookGraph book={book} />
                  </li>
                ))}
              </ul>
            )}

            {/* Pagination */}
            <div className="pagination">
              {[...Array(totalPages).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={currentPage === number + 1 ? 'active' : ''}
                >
                  {number + 1}
                </button>
              ))}
            </div>
          </div>
        </>
      )}

      {isPopupVisible && (
        <div className="image-popup" onClick={closePopup}>
          <span className="close" onClick={closePopup}>&times;</span>
          <img className="popup-image" src={selectedImage} alt="Book" />
        </div>
      )}

      {isBioImageOpen && (
        <div className="bio-image-popup" onClick={closeBioImageModal}>
          <span className="close" onClick={closeBioImageModal}>&times;</span>
          <img className="popup-image" src={selectedImage} alt="Author Biography" />
        </div>
      )}
    </div>
  );
};
// Get all the ebook images
const ebookImages = document.querySelectorAll('.ebook-image');

// Add event listener to each image
ebookImages.forEach(image => {
  image.addEventListener('click', () => {
    // Toggle the 'clicked' class
    image.classList.toggle('clicked');
  });
});

export default BookList;


