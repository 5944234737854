

// import React, { useEffect, useState } from 'react';
// import './PaymentForm.css';

// const PaymentForm = () => {
//   const [amount, setAmount] = useState('');
//   const [email, setEmail] = useState('');
//   const [downloadLinks, setDownloadLinks] = useState([]);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const amountParam = urlParams.get('amount');
//     const downloadLinksParam = urlParams.get('downloadLinks');

//     setAmount(amountParam || ''); // Ensure this captures the correct amount
//     setDownloadLinks(downloadLinksParam ? JSON.parse(decodeURIComponent(downloadLinksParam)) : []);
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!amount || !email) {
//       alert("Please provide both the amount and email.");
//       return;
//     }

//     const numericAmount = parseFloat(amount);
//     if (numericAmount < 0 || numericAmount > 100000) {
//       alert("Amount must be between 0 and 100,000 Ksh.");
//       return;
//     }

//     const updatedDownloadLinks = downloadLinks.map(link => ({
//       ...link,
//       link: link.link.replace(/^http:\/\/yourwebsite.com/, window.location.origin),
//     }));

//     const token = generateDRMToken(email, amount);

//     const actionUrl = `https://439c-197-248-212-25.ngrok-free.app/pesa/index.php?amount=${encodeURIComponent(amount)}&email=${encodeURIComponent(email)}&downloadLinks=${encodeURIComponent(JSON.stringify(updatedDownloadLinks))}&token=${encodeURIComponent(token)}`;

//     window.location.href = actionUrl;
//   };

//   const generateDRMToken = (email, amount) => {
//     const timestamp = new Date().getTime();
//     return btoa(`${email}:${amount}:${timestamp}`);
//   };

//   return (
//     <div className="payment-form">
//       <h2>Payment Details</h2>
//       {amount && <p>Amount to be paid:   {amount} Fanon</p>}
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label htmlFor="email">ENTER YOUR EMAIL</label>
//           <input
//             type="email"
//             name="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit">Proceed to Payment</button>
//       </form>
//     </div>
//   );
// };

// export default PaymentForm;

// import React, { useEffect, useState } from 'react';
// import './PaymentForm.css';

// const PaymentForm = () => {
//   const [amount, setAmount] = useState('');
//   const [email, setEmail] = useState('');
//   const [downloadLinks, setDownloadLinks] = useState([]);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const amountParam = urlParams.get('amount');
//     const downloadLinksParam = urlParams.get('downloadLinks');

//     setAmount(amountParam || '');
//     setDownloadLinks(downloadLinksParam ? JSON.parse(decodeURIComponent(downloadLinksParam)) : []);
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!amount || !email) {
//       alert("Please provide both the amount and email.");
//       return;
//     }

//     const numericAmount = parseFloat(amount);
//     if (numericAmount < 0 || numericAmount > 100000) {
//       alert("Amount must be between 0 and 100,000 Ksh.");
//       return;
//     }

//     const updatedDownloadLinks = downloadLinks.map(link => ({
//       ...link,
//       link: link.link.replace(/^http:\/\/yourwebsite.com/, window.location.origin),
//     }));

//     const token = generateDRMToken(email, amount);

//     const actionUrl = `https://439c-197-248-212-25.ngrok-free.app/pesa/index.php?amount=${encodeURIComponent(amount)}&email=${encodeURIComponent(email)}&downloadLinks=${encodeURIComponent(JSON.stringify(updatedDownloadLinks))}&token=${encodeURIComponent(token)}`;

//     window.location.href = actionUrl;
//   };

//   const generateDRMToken = (email, amount) => {
//     const timestamp = new Date().getTime();
//     return btoa(`${email}:${amount}:${timestamp}`);
//   };

//   return (
//     <div className="payment-form">
//       <h2>Payment Details</h2>
//       {amount && <p>Amount to be paid: {amount} Fanon</p>}
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label htmlFor="email">ENTER YOUR EMAIL</label>
//           <input
//             type="email"
//             name="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit">Proceed to Payment</button>
//       </form>
//     </div>
//   );
// };

// export default PaymentForm;


// import React, { useEffect, useState } from 'react';
// import './PaymentForm.css';

// const PaymentForm = () => {
//   const [amount, setAmount] = useState('');
//   const [email, setEmail] = useState('');
//   const [downloadLinks, setDownloadLinks] = useState([]);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const amountParam = urlParams.get('amount');
//     const downloadLinksParam = urlParams.get('downloadLinks');

//     setAmount(amountParam || '');
//     setDownloadLinks(downloadLinksParam ? JSON.parse(decodeURIComponent(downloadLinksParam)) : []);
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!amount || !email) {
//       alert("Please provide both the amount and email.");
//       return;
//     }

//     const numericAmount = parseFloat(amount);
//     if (numericAmount < 0 || numericAmount > 100000) {
//       alert("Amount must be between 0 and 100,000 Ksh.");
//       return;
//     }

//     const updatedDownloadLinks = downloadLinks.map(link => ({
//       ...link,
//       link: link.link.replace(/^http:\/\/yourwebsite.com/, window.location.origin),
//     }));

//     const token = generateDRMToken(email, amount);

//     const actionUrl = `https://439c-197-248-212-25.ngrok-free.app/pesa/index.php?amount=${encodeURIComponent(amount)}&email=${encodeURIComponent(email)}&downloadLinks=${encodeURIComponent(JSON.stringify(updatedDownloadLinks))}&token=${encodeURIComponent(token)}`;

//     window.location.href = actionUrl;
//   };

//   const generateDRMToken = (email, amount) => {
//     const timestamp = new Date().getTime();
//     return btoa(`${email}:${amount}:${timestamp}`);
//   };

//   return (
//     <div className="payment-form">
//       <h2>Payment Details</h2>
//       {amount && <p>Amount to be paid: {amount} Fanon</p>}
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label htmlFor="email">ENTER YOUR EMAIL</label>
//           <input
//             type="email"
//             name="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit">Proceed to Payment</button>
//       </form>
//     </div>
//   );
// };

// export default PaymentForm;


// import React, { useEffect, useState } from 'react';

// const PaymentForm = () => {
//   const [amount, setAmount] = useState('');
//   const [email, setEmail] = useState('');
//   const [downloadLinks, setDownloadLinks] = useState([]);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     setAmount(urlParams.get('amount') || '');
//     const linksParam = urlParams.get('downloadLinks');
//     setDownloadLinks(linksParam ? JSON.parse(decodeURIComponent(linksParam)) : []);
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!amount || !email) {
//       alert("Please provide both the amount and email.");
//       return;
//     }

//     const token = generateDRMToken(email, amount);
//     const actionUrl = `https://7c11-197-248-212-25.ngrok-free.app/pesa/index.php?amount=${encodeURIComponent(amount)}&email=${encodeURIComponent(email)}&downloadLinks=${encodeURIComponent(JSON.stringify(downloadLinks))}&token=${encodeURIComponent(token)}`;

//     window.location.href = actionUrl;
//   };

//   const generateDRMToken = (email, amount) => {
//     const timestamp = new Date().getTime();
//     return btoa(`${email}:${amount}:${timestamp}`);
//   };

//   return (
//     <div>
//       <h2>Payment Details</h2>
//       {amount && <p>Amount: {amount} Fanon</p>}
//       <form onSubmit={handleSubmit}>
//         <label>Email:</label>
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required
//         />
//         <button type="submit">Proceed to Payment</button>
//       </form>
//     </div>
//   );
// };

// export default PaymentForm;


// import React, { useEffect, useState } from 'react';

// const PaymentForm = () => {
//   const [amount, setAmount] = useState('');
//   const [downloadLinks, setDownloadLinks] = useState([]);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     setAmount(urlParams.get('amount') || '');
//     const linksParam = urlParams.get('downloadLinks');
//     setDownloadLinks(linksParam ? JSON.parse(decodeURIComponent(linksParam)) : []);
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!amount || !downloadLinks.length) {
//       alert("Please provide both the amount and download links.");
//       return;
//     }

//     // Generate a DRM token for the download (no email needed)
//     const token = generateDRMToken(amount);
//     const actionUrl = `https://5bbd-197-248-212-25.ngrok-free.app/pesa/index.php?amount=${encodeURIComponent(amount)}&downloadLinks=${encodeURIComponent(JSON.stringify(downloadLinks))}&token=${encodeURIComponent(token)}`;

//     window.location.href = actionUrl;
//   };

//   const generateDRMToken = (amount) => {
//     const timestamp = new Date().getTime();
//     return btoa(`${amount}:${timestamp}`);
//   };

//   return (
//     <div>
//       <h2>Payment Details</h2>
//       {amount && <p>Amount: {amount} Fanon</p>}
//       <form onSubmit={handleSubmit}>
//         <button type="submit">Proceed to Payment</button>
//       </form>
//     </div>
//   );
// };

// export default PaymentForm;

// import React, { useEffect, useState } from 'react';


// const PaymentForm = () => {
//   const [amount, setAmount] = useState('');
//   const [downloadLinks, setDownloadLinks] = useState([]);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     setAmount(urlParams.get('amount') || '');
//     const linksParam = urlParams.get('downloadLinks');
//     setDownloadLinks(linksParam ? JSON.parse(decodeURIComponent(linksParam)) : []);
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!amount || !downloadLinks.length) {
//       alert("Please provide both the amount and download links.");
//       return;
//     }

//     // Generate a DRM token for secure download
//     const token = generateDRMToken(amount);
//     const actionUrl = `https://8ddf-197-248-212-25.ngrok-free.app/pesa/index.php?amount=${encodeURIComponent(amount)}&downloadLinks=${encodeURIComponent(JSON.stringify(downloadLinks))}&token=${encodeURIComponent(token)}`;

//     // Redirect to the payment gateway with the necessary parameters
//     window.location.href = actionUrl;
//   };

//   const generateDRMToken = (amount) => {
//     const timestamp = new Date().getTime();
//     return btoa(`${amount}:${timestamp}`);
//   };

//   return (
//     <div>
//       <h2>Payment Details</h2>
//       {amount && <p>Amount: {amount} Fanon</p>}
//       <form onSubmit={handleSubmit}>
//         <button type="submit">Proceed to Payment</button>
//       </form>
//     </div>
//   );
// };

// export default PaymentForm;

import React, { useEffect, useState } from 'react';
import './PaymentForm.css'; // Import the CSS file

const PaymentForm = () => {
  const [amount, setAmount] = useState('');
  const [downloadLinks, setDownloadLinks] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setAmount(urlParams.get('amount') || '');
    const linksParam = urlParams.get('downloadLinks');
    setDownloadLinks(linksParam ? JSON.parse(decodeURIComponent(linksParam)) : []);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!amount || !downloadLinks.length) {
    //   alert("Please provide both the amount and download links.");
    //   return;
    // }

    // Generate a DRM token for secure download
    const token = generateDRMToken(amount);

    const actionUrl = `http://195.35.48.252:8000/pesa/index.php?amount=${encodeURIComponent(amount)}&downloadLinks=${encodeURIComponent(JSON.stringify(downloadLinks))}&token=${encodeURIComponent(token)}`;

    // Redirect to the payment gateway with the necessary parameters
    window.location.href = actionUrl;
  };

  const generateDRMToken = (amount) => {
    const timestamp = new Date().getTime();
    return btoa(`${amount}:${timestamp}`);
  };

  return (
    <div>
      <h2 className="payment-heading">Payment Details</h2> {/* Apply the CSS class */}
      {amount && <p>Amount: {amount} Fanon</p>}
      <form onSubmit={handleSubmit}>
        <button type="submit">Proceed to Payment</button>
      </form>
    </div>
  );
};

export default PaymentForm;

