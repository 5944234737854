// import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';

// function DownloadPage() {
//   const [downloadLinks, setDownloadLinks] = useState([]);
//   const location = useLocation();

//   useEffect(() => {
//     // Get the download links from the URL query parameters
//     const params = new URLSearchParams(location.search);
//     const links = JSON.parse(decodeURIComponent(params.get('downloadLinks') || '[]'));
//     setDownloadLinks(links);
//   }, [location]);

//   const handleDownload = (link) => {
//     // Logic to download the file or navigate to the file URL
//     window.location.href = link; // This can also be customized for different download logic
//   };

//   return (
//     <div>
//       <h1>Download Your eBooks</h1>
//       {downloadLinks.length > 0 ? (
//         <ul>
//           {downloadLinks.map((book, index) => (
//             <li key={index}>
//               <button onClick={() => handleDownload(book.link)}>
//                 Download {book.title}
//               </button>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <p>No download links available. Please try again.</p>
//       )}
//     </div>
//   );
// }

// export default DownloadPage;


// import React, { useEffect, useState } from 'react';

// const DownloadPage = () => {
//   const [downloadLink, setDownloadLink] = useState('');

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     setDownloadLink(urlParams.get('link') || '');
//   }, []);

//   const handleDownload = () => {
//     if (downloadLink) {
//       window.location.href = downloadLink; // Trigger download by navigating to the link
//     }
//   };

//   return (
//     <div>
//       <h2>Download Your Ebook</h2>
//       {downloadLink ? (
//         <div>
//           <p>Your ebook is ready for download!</p>
//           <button onClick={handleDownload}>Download Now</button>
//         </div>
//       ) : (
//         <p>No download link found.</p>
//       )}
//     </div>
//   );
// };

// export default DownloadPage;


// import React, { useEffect, useState } from 'react';
// import './DownloadPage.css'; // Import the CSS file

// const DownloadPage = () => {
//   const [downloadLink, setDownloadLink] = useState('');

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     setDownloadLink(urlParams.get('link') || '');
//   }, []);

//   const handleDownload = () => {
//     if (downloadLink) {
//       window.location.href = downloadLink; // Trigger download by navigating to the link
//     }
//   };

//   return (
//     <div className="download-page">
//       <h2>Thank you for your purchase! Click below to download your ebook(s).</h2>
//       {downloadLink ? (
//         <div>
//           <p>Your ebook is ready for download!</p>
//           <button onClick={handleDownload}>Download Now</button>
//         </div>
//       ) : (
//         <p className="no-link">No download link found.</p>
//       )}
//     </div>
//   );
// };

// export default DownloadPage;


// import React, { useEffect, useState } from 'react';
// import './DownloadPage.css'; // Import the CSS file

// const DownloadPage = () => {
//   const [downloadLinks, setDownloadLinks] = useState([]);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const linksParam = urlParams.get('links');
//     setDownloadLinks(linksParam ? JSON.parse(decodeURIComponent(linksParam)) : []);
//   }, []);

//   const handleDownload = (link) => {
//     window.location.href = link; // Trigger download by navigating to the link
//   };

//   return (
//     <div className="download-page">
//       <h2>Thank you for your purchase! Click below to download your ebook(s).</h2>
//       {downloadLinks.length > 0 ? (
//         <div>
//           {downloadLinks.map((item, index) => (
//             <div key={index}>
//               <p>{item.title}</p>
//               <button onClick={() => handleDownload(item.link)}>Download {item.title}</button>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <p>No download links found.</p>
//       )}
//     </div>
//   );
// };

// export default DownloadPage;


import React, { useState } from 'react';
import './DownloadPage.css'; // Import the CSS file

const DownloadPage = () => {
  const [downloadLinks] = useState([
    {
      
      link: 'http://195.35.48.252:4000/api/books/download/672353e549318bb4260afa1f',
    },
  ]);

  const handleDownload = (link) => {
    window.location.href = link; // Trigger download by navigating to the link
  };

  return (
    <div className="download-page">
      <h2>Thank you for your purchase! Click below to download your ebook(s).</h2>
      {downloadLinks.length > 0 ? (
        <div>
          {downloadLinks.map((item, index) => (
            <div key={index}>
              <p>{item.title}</p>
              <button onClick={() => handleDownload(item.link)}>Download {item.title}</button>
            </div>
          ))}
        </div>
      ) : (
        <p>No download links found.</p>
      )}
    </div>
  );
};

export default DownloadPage;
