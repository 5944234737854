// // Founder.js
// import React from 'react';
// import './founder.css';
// import founderImage from './images/Owano.jpg'; // Adjust the path if your image folder structure is different

// const Founder = () => {
//   return (
//     <div className="founder-container">
//       <img src={founderImage} alt="Franz Owano" className="founder-image" />
//       <div className="founder-info">
//         <h2>About the Founder</h2>
//         <p>
//           <strong>Franz Owano</strong> is a medical doctor based in Nairobi, Kenya. With a passion for literature,
//           he has authored six plays, a journal, two novellas, and three collections of short stories. When not working,
//           he enjoys reading, writing, and travelling.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Founder;

// Founder.js
// import React from 'react';
// import './founder1.css';
// import founderImage from './images/Owano.jpg'; // Adjust the path if your image folder structure is different

// const Founder = () => {
//   return (
    
//     <div className="founder-container" >
//       <img src={founderImage} alt="Franz Owano" className="founder-image" />
//       <div className="founder-info">
//         <h2>About the Founder</h2>
//         <p>
//           <strong>Franz Owano</strong> (Medical Doctor, Author, Publisher) began writing seriously over a decade ago while still a medical student at the University of Nairobi. Seeking a creative outlet from the demands of his profession, he ventured into literature, transforming his experiences with the human condition as a medical practitioner into compelling literary works.
//         </p>
//         <p>
//           His published works include five plays, four short story collections, an anthology of poems, a novella, and two novels, spanning genres from contemporary fiction, African folklore, and religious fantasy to historical and science fiction. His notable titles recognized internationally include:
//         </p>
//         <ul>
//           <li><strong>The Chief Must Die</strong> (2021): A collection of essays and short stories.</li>
//           <li><strong>The Heart of a Prince</strong> (2022): A historical fiction novel.</li>
//           <li><strong>Simbi</strong> (2023): A collaborative anthology of essays and short stories.</li>
//           <li><strong>Simians</strong> (2023): A novella by George Gathiani, published under Wanderer’s Path Publishers.</li>
//           <li><strong>Visions of Chrysalis</strong> (2024): An afro-futurist science fiction novel.</li>
//         </ul>
//         <p>
//           Franz founded <strong>Wanderer’s Path Publishers</strong> to support creative voices in Kenya, encouraging writers to express themselves freely outside the confines of traditional publishing. Additionally, he established the <strong>Pan African Book Exchange</strong>, a dynamic online ebook market, to foster collective knowledge and literary engagement across the continent.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Founder;

import React from 'react';
import './founder.css';
import founderImage from './images/Owano.jpg';

const Founder = () => {
  return (
    <div className="founder-container">
      <div className="founder-image-container">
        <img src={founderImage} alt="Franz Owano" className="founder-image" />
      </div>
      <div className="founder-info">
        <h2>About the Founder</h2>
        <p>
          <strong>Franz Owano</strong> (Medical Doctor, Author, Publisher) began writing seriously over a decade ago while still a medical student at the University of Nairobi. Seeking a creative outlet from the demands of his profession, he ventured into literature, transforming his experiences with the human condition as a medical practitioner into compelling literary works.
        </p>
        <p>
          His published works include five plays, four short story collections, an anthology of poems, a novella, and two novels, spanning genres from contemporary fiction, African folklore, and religious fantasy to historical and science fiction. His notable titles recognized internationally include:
        </p>
        <ul>
          <li><strong>The Chief Must Die</strong> (2021): A collection of essays and short stories.</li>
          <li><strong>The Heart of a Prince</strong> (2022): A historical fiction novel.</li>
          <li><strong>Simbi</strong> (2023): A collaborative anthology of essays and short stories.</li>
          <li><strong>Simians</strong> (2023): A novella by George Gathiani, published under Wanderer’s Path Publishers.</li>
          <li><strong>Visions of Chrysalis</strong> (2024): An afro-futurist science fiction novel.</li>
        </ul>
        <p>
          Franz founded <strong>Wanderer’s Path Publishers</strong> to support creative voices in Kenya, encouraging writers to express themselves freely outside the confines of traditional publishing. Additionally, he established the <strong>Pan African Book Exchange</strong>, a dynamic online ebook market, to foster collective knowledge and literary engagement across the continent.
        </p>
      </div>
    </div>
  );
};

export default Founder;
