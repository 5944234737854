

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddBookForm from './components/AddBookForm';
import BookList from './components/BookList';
import BookGraph from './components/BookGraph';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './components/HomePage';
import ArtList from './components/ArtList';
import AddArtForm from './components/AddArtForm';
import Cart from './components/Cart';
import PaymentForm from './components/PaymentForm'; 
import DownloadPage from './components/DownloadPage'; 
import Founder from './components/Founder'; // Import Founder component
import './App.css';
import logo from './images/logo.png';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        
        <h1>PAN AFRICAN BOOK EXCHANGE</h1>
      
        <nav>
          <h2>EXPLORE YOUR EBOOK COLLECTION AND ART COLLECTION</h2>
        </nav>
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/ebooks" element={<BookList />} />
          <Route path="/arts" element={<ArtList />} />
          <Route path="/add-book" element={<AddBookForm />} />
          <Route path="/add-arts" element={<AddArtForm />} />
          <Route path="/graph/:id" element={<BookGraph />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/register" element={<Register />} />
          <Route path="/payment-form" element={<PaymentForm />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/founder" element={<Founder />} /> {/* New Founder route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
