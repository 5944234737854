// // client/src/components/Login.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import './login.css'; // Import CSS file

// const Login = ({ isOpen, onClose }) => {
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://195.35.48.252:4000/api/auth/login', { username, password });
//             localStorage.setItem('token', response.data.token); // Store token
//             localStorage.setItem('role', response.data.role); // Store role
//             localStorage.setItem('username', username); // Store username
//             onClose(); // Close modal on successful login
//             window.location.href = '/ebooks'; // Redirect to dashboard
//         } catch (error) {
//             console.error('Login failed', error);
//         }
//     };

//     if (!isOpen) return null; // Don't render the modal if it's not open

//     return (
//         <div className="modal-overlay">
//             <div className="modal-content">
//                 <span className="close" onClick={onClose}>&times;</span>
//                 <h2 className='login'>Login</h2>
//                 <form onSubmit={handleSubmit}>
//                     <input
//                         className="input"
//                         type="text"
//                         placeholder="Username"
//                         onChange={(e) => setUsername(e.target.value)}
//                         required
//                     />
//                     <input
//                         className="input"
//                         type="password"
//                         placeholder="Password"
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                     />
//                     <button className="button" type="submit">Login</button>
//                 </form>
//                 <p>Don't have an Account? <a href='/'>Register</a> </p>
//             </div>
//         </div>
//     );
// };

// export default Login;

import React, { useState } from 'react';
import axios from 'axios';
import './login.css';
import ForgotPassword from './ForgotPassword';

const Login = ({ isOpen, onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);

    const handleForgotPasswordClick = () => {
        setIsForgotPasswordOpen(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://195.35.48.252:4000/api/auth/login', { username, password });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('role', response.data.role);
            localStorage.setItem('username', username);
            onClose();
            window.location.href = '/ebooks';
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2 className='login'>Login</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        className="input"
                        type="text"
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        className="input"
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button className="button" type="submit">Login</button>
                </form>
                <p>Don't have an Account? <a href='/'>Register</a></p>
                <p><a href="#" onClick={handleForgotPasswordClick}>Forgot Password?</a></p>
                {isForgotPasswordOpen && (
                    <ForgotPassword isOpen={isForgotPasswordOpen} onClose={() => setIsForgotPasswordOpen(false)} />
                )}
            </div>
        </div>
    );
};

export default Login;
