


import React, { useState } from 'react';
import axios from 'axios';
import './Register.css';

const Register = ({ isOpen, onClose }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        // Validate email format
        if (!emailRegex.test(emailValue)) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!emailRegex.test(email)) {
            alert('Please enter a valid email');
            return;
        }

        try {
            const response = await axios.post('http://195.35.48.252:4000/api/auth/register', { username, email, password });
            console.log('User registered successfully:', response.data);
            alert('User registered successfully');
            onClose();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                console.log('Registration error:', error.response.data.error);
                if (error.response.data.error === 'User with this email already exists') {
                    alert('This email is already in use');
                } else if (error.response.data.error === 'Invalid email format') {
                    alert('Invalid email format');
                } else {
                    alert('Registration failed! User already exists.');
                }
            } else {
                console.error('Registration error:', error);
                alert('An error occurred during registration');
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2 className='register'>Register</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        className="input"
                        type="text"
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        className="input"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                    {emailError && <p className="error">{emailError}</p>}
                    <input
                        className="input"
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button className="button" type="submit">Register</button>
                </form>
                <p>Already have an Account? <a href='/'>Login</a> </p>
            </div>
        </div>
    );
};

export default Register;
