// import React, { useState } from 'react';
// import axios from 'axios';
// import './ForgotPassword.css';

// const ForgotPassword = ({ isOpen, onClose }) => {
//     const [email, setEmail] = useState('');
//     const [message, setMessage] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://195.35.48.252:4000/api/auth/forgot-password', { email });
//             setMessage('If the email is registered, a reset link will be sent.');
//         } catch (error) {
//             console.error('Error sending reset email:', error);
//             setMessage('An error occurred. Please try again later.');
//         }
//     };

//     if (!isOpen) return null;

//     return (
//         <div className="modal-overlay">
//             <div className="modal-content">
//                 <span className="close" onClick={onClose}>&times;</span>
//                 <h2 className='forgot-password'>Forgot Password</h2>
//                 <form onSubmit={handleSubmit}>
//                     <input
//                         className="input"
//                         type="email"
//                         placeholder="Enter your email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                     />
//                     <button className="button" type="submit">Reset Password</button>
//                 </form>
//                 {message && <p>{message}</p>}
//             </div>
//         </div>
//     );
// };

// export default ForgotPassword;


import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css';

const ForgotPassword = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://195.35.48.252:4000/api/auth/forgot-password', { email });
            setMessage('If the email is registered, a reset link will be sent.');
        } catch (error) {
            console.error('Error sending reset email:', error);
            setMessage('An error occurred. Please try again later.');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2 className='forgot-password'>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        className="input"
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button className="button" type="submit">Reset Password</button>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default ForgotPassword;
