


// import React from 'react';
// import './Cart.css';

// const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
//   const calculateTotalAmount = () => {
//     return cart.reduce((total, item) => total + item.price, 0);
//   };

//   const generateRandomLink = (bookTitle) => {
//     // Generate a random link based on the book title
//     const randomId = Math.floor(Math.random() * 10000); // Random ID for the book
//     // return `http://yourwebsite.com/downloads/${bookTitle.replace(/\s+/g, '-').toLowerCase()}-${randomId}.pdf`;
//     return `https://4657-197-248-212-25.ngrok-free.app/downloads/${bookTitle.replace(/\s+/g, '-').toLowerCase()}-${randomId}.pdf`;
//   };

//   const handleCheckout = () => {
//     const totalAmount = calculateTotalAmount();
//     // Prepare the download links for each book
//     const downloadLinks = cart.map(item => ({
//       title: item.title,
//       link: generateRandomLink(item.title)
//     }));

//     // Redirect to the payment form with totalAmount and downloadLinks as query parameters
//     const downloadLinksParam = encodeURIComponent(JSON.stringify(downloadLinks));
//     window.location.href = `/payment-form?amount=${totalAmount}&downloadLinks=${downloadLinksParam}`;
//   };

//   return (
//     <div className="cart">
//       <h2 className="h2">YOUR CART</h2>
//       {cart.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <>
//           <ul>
//             {cart.map((item, index) => (
//               <li key={index} className="cart-item">
//                 {item.imagePath && (
//                   <img
//                     src={`http://195.35.48.252:4000/${item.imagePath}`}
//                     alt={item.title}
//                     className="cart-item-image"
//                   />
//                 )}
//                 <div className="cart-item-details">
//                   <h4>{item.title} by {item.author}</h4>
//                   <p>Price: KES {item.price}</p>
//                 </div>
//                 <button
//                   className="remove-btn"
//                   onClick={() => onRemoveFromCart(item._id)}
//                 >
//                   Remove
//                 </button>
//               </li>
//             ))}
//           </ul>
//           <div className="cart-total">
//             <h3>Total Amount: KES {calculateTotalAmount()}</h3>
//           </div>
//         </>
//       )}
//       <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
//       <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>
//     </div>
//   );
// };

// export default Cart;


// import React from 'react';
// import './Cart.css';

// const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
//   const USD_TO_FANON = 100; // Conversion rate: 1 USD = 100 Fanon

//   const calculateTotalAmountInFanon = () => {
//     return cart.reduce((total, item) => total + item.price, 0);
//   };

//   const calculateTotalAmountInUSD = () => {
//     const totalFanon = calculateTotalAmountInFanon();
//     return (totalFanon / USD_TO_FANON).toFixed(2); // Convert total to USD
//   };

//   const generateRandomLink = (bookTitle) => {
//     const randomId = Math.floor(Math.random() * 10000);
//     return `https://4657-197-248-212-25.ngrok-free.app/downloads/${bookTitle.replace(/\s+/g, '-').toLowerCase()}-${randomId}.pdf`;
//   };

//   const handleCheckout = () => {
//     const totalAmountFanon = calculateTotalAmountInFanon();
//     const downloadLinks = cart.map(item => ({
//       title: item.title,
//       link: generateRandomLink(item.title)
//     }));

//     const downloadLinksParam = encodeURIComponent(JSON.stringify(downloadLinks));
//     window.location.href = `/payment-form?amount=${totalAmountFanon}&downloadLinks=${downloadLinksParam}`;
//   };

//   return (
//     <div className="cart">
//       <h2 className="h2">YOUR CART</h2>
//       {cart.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <>
//           <ul>
//             {cart.map((item, index) => (
//               <li key={index} className="cart-item">
//                 {item.imagePath && (
//                   <img
//                     src={`http://195.35.48.252:4000/${item.imagePath}`}
//                     alt={item.title}
//                     className="cart-item-image"
//                   />
//                 )}
//                 <div className="cart-item-details">
//                   <h4>{item.title} by {item.author}</h4>
//                   <p>Price: {item.price} Fanon (USD {(item.price / USD_TO_FANON).toFixed(2)})</p>
//                 </div>
//                 <button
//                   className="remove-btn"
//                   onClick={() => onRemoveFromCart(item._id)}
//                 >
//                   Remove
//                 </button>
//               </li>
//             ))}
//           </ul>
//           <div className="cart-total">
//             <h3>Total Amount: {calculateTotalAmountInFanon()} Fanon (USD {calculateTotalAmountInUSD()})</h3>
//           </div>
//         </>
//       )}
//       <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
//       <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>
//     </div>
//   );
// };

// export default Cart;


// import React from 'react';
// import './Cart.css';

// const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
//   const USD_TO_FANON = 100; // Conversion rate: 1 USD = 100 Fanon

//   const calculateTotalAmountInFanon = () => {
//     return cart.reduce((total, item) => total + item.price, 0);
//   };

//   const calculateTotalAmountInUSD = () => {
//     const totalFanon = calculateTotalAmountInFanon();
//     return (totalFanon / USD_TO_FANON).toFixed(2); // Convert total to USD
//   };

//   const generateRandomLink = (bookTitle) => {
//     const randomId = Math.floor(Math.random() * 10000);
//     return `https://4657-197-248-212-25.ngrok-free.app/downloads/${bookTitle.replace(/\s+/g, '-').toLowerCase()}-${randomId}.pdf`;
//   };

//   const handleCheckout = () => {
//     const totalAmountFanon = calculateTotalAmountInFanon();
//     const downloadLinks = cart.map(item => ({
//       title: item.title,
//       link: generateRandomLink(item.title),
//     }));
  
//     const downloadLinksParam = encodeURIComponent(JSON.stringify(downloadLinks));
//     window.location.href = `/payment-form?amount=${totalAmountFanon}&downloadLinks=${downloadLinksParam}`;
//   };
  

//   return (
//     <div className="cart">
//       <h2 className="h2">YOUR CART</h2>
//       {cart.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <>
//           <ul>
//             {cart.map((item, index) => (
//               <li key={index} className="cart-item">
//                 {item.imagePath && (
//                   <img
//                     src={`http://195.35.48.252:4000/${item.imagePath}`}
//                     alt={item.title}
//                     className="cart-item-image"
//                   />
//                 )}
//                 <div className="cart-item-details">
//                   <h4>{item.title} by {item.author}</h4>
//                   <p>Price: {item.price} Fanon (USD {(item.price / USD_TO_FANON).toFixed(2)})</p>
//                 </div>
//                 <button
//                   className="remove-btn"
//                   onClick={() => onRemoveFromCart(item._id)}
//                 >
//                   Remove
//                 </button>
//               </li>
//             ))}
//           </ul>
//           <div className="cart-total">
//             <h3>Total Amount: {calculateTotalAmountInFanon()} Fanon (USD {calculateTotalAmountInUSD()})</h3>
//           </div>
//         </>
//       )}
//       <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
//       <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>
//     </div>
//   );
// };

// export default Cart;


// import React from 'react';
// import './Cart.css';

// const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
//   const USD_TO_FANON = 100; // Conversion rate: 1 USD = 100 Fanon

//   const calculateTotalAmountInFanon = () => {
//     return cart.reduce((total, item) => total + item.price, 0);
//   };

//   const calculateTotalAmountInUSD = () => {
//     const totalFanon = calculateTotalAmountInFanon();
//     return (totalFanon / USD_TO_FANON).toFixed(2); // Convert total to USD
//   };

//   const generateRandomLink = (bookTitle) => {
//     const randomId = Math.floor(Math.random() * 10000);
//     return `https://4657-197-248-212-25.ngrok-free.app/downloads/${bookTitle.replace(/\s+/g, '-').toLowerCase()}-${randomId}.pdf`;
//   };

//   const handleCheckout = () => {
//     const totalAmountFanon = calculateTotalAmountInFanon();
//     const downloadLinks = cart.map(item => ({
//       title: item.title,
//       link: generateRandomLink(item.title), // Generate links for each book
//     }));
  
//     // Ensure downloadLinks are correctly encoded in the URL
//     const downloadLinksParam = encodeURIComponent(JSON.stringify(downloadLinks));
    
//     // Redirect to payment form with the necessary parameters
//     window.location.href = `/payment-form?amount=${totalAmountFanon}&downloadLinks=${downloadLinksParam}`;
//   };
  


//   return (
//     <div className="cart">
//       <h2 className="h2">YOUR CART</h2>
//       {cart.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <>
//           <ul>
//             {cart.map((item, index) => (
//               <li key={index} className="cart-item">
//                 {item.imagePath && (
//                   <img
//                     src={`http://195.35.48.252:4000/${item.imagePath}`}
//                     alt={item.title}
//                     className="cart-item-image"
//                   />
//                 )}
//                 <div className="cart-item-details">
//                   <h4>{item.title} by {item.author}</h4>
//                   <p>Price: {item.price} Fanon (USD {(item.price / USD_TO_FANON).toFixed(2)})</p>
//                 </div>
//                 <button
//                   className="remove-btn"
//                   onClick={() => onRemoveFromCart(item._id)}
//                 >
//                   Remove
//                 </button>
//               </li>
//             ))}
//           </ul>
//           <div className="cart-total">
//             <h3>Total Amount: {calculateTotalAmountInFanon()} Fanon (USD {calculateTotalAmountInUSD()})</h3>
//           </div>
//         </>
//       )}
//       <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
//       <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>
//     </div>
//   );
// };

// export default Cart;


// import React from 'react';
// import './Cart.css';

// const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
//   const USD_TO_FANON = 100; // Conversion rate: 1 USD = 100 Fanon

//   // Calculate total in Fanon
//   const calculateTotalAmountInFanon = () => {
//     return cart.reduce((total, item) => total + item.price, 0);
//   };

//   // Calculate total in USD
//   const calculateTotalAmountInUSD = () => {
//     const totalFanon = calculateTotalAmountInFanon();
//     return (totalFanon / USD_TO_FANON).toFixed(2); // Convert total to USD
//   };

//   // Function to generate random download link for each ebook
//   const generateRandomLink = (bookTitle) => {
//     const randomId = Math.floor(Math.random() * 10000); // Generate a random ID for uniqueness
//     return `https://4657-197-248-212-25.ngrok-free.app/downloads/${bookTitle.replace(/\s+/g, '-').toLowerCase()}-${randomId}.pdf`; // Replace spaces in title with dashes and convert to lowercase
//   };

//   // Handle checkout process and pass generated links
//   const handleCheckout = () => {
//     const totalAmountFanon = calculateTotalAmountInFanon();
  
//     // Generate download links for all items in the cart
//     const downloadLinks = cart.map(item => ({
//       title: item.title,
//       link: generateRandomLink(item.title), // Generate unique download link for each book
//     }));
  
//     // Ensure download links are correctly encoded in the URL
//     const downloadLinksParam = encodeURIComponent(JSON.stringify(downloadLinks));
  
//     // Redirect to payment form with the necessary parameters (amount and download links)
//     window.location.href = `/payment-form?amount=${totalAmountFanon}&downloadLinks=${downloadLinksParam}`;
//   };
  

//   return (
//     <div className="cart">
//       <h2 className="h2">YOUR CART</h2>
//       {cart.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <>
//           <ul>
//             {cart.map((item, index) => (
//               <li key={index} className="cart-item">
//                 {item.imagePath && (
//                   <img
//                     src={`http://195.35.48.252:4000/${item.imagePath}`}
//                     alt={item.title}
//                     className="cart-item-image"
//                   />
//                 )}
//                 <div className="cart-item-details">
//                   <h4>{item.title} by {item.author}</h4>
//                   <p>Price: {item.price} Fanon (USD {(item.price / USD_TO_FANON).toFixed(2)})</p>
//                 </div>
//                 <button
//                   className="remove-btn"
//                   onClick={() => onRemoveFromCart(item._id)}
//                 >
//                   Remove
//                 </button>
//               </li>
//             ))}
//           </ul>
//           <div className="cart-total">
//             <h3>Total Amount: {calculateTotalAmountInFanon()} Fanon (USD {calculateTotalAmountInUSD()})</h3>
//           </div>
//         </>
//       )}
//       <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
//       <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>
//     </div>
//   );
// };

// export default Cart;


// import React from 'react';
// import './Cart.css';

// const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
//   const USD_TO_FANON = 100; // Conversion rate: 1 USD = 100 Fanon

//   // Calculate total in Fanon
//   const calculateTotalAmountInFanon = () => {
//     return cart.reduce((total, item) => total + item.price, 0);
//   };

//   // Calculate total in USD
//   const calculateTotalAmountInUSD = () => {
//     const totalFanon = calculateTotalAmountInFanon();
//     return (totalFanon / USD_TO_FANON).toFixed(2); // Convert total to USD
//   };

//   // Function to generate random download link for each ebook
//   const generateRandomLink = (bookTitle) => {
//     const randomId = Math.floor(Math.random() * 10000); // Generate a random ID for uniqueness
//     return `https://4657-197-248-212-25.ngrok-free.app/downloads/${bookTitle.replace(/\s+/g, '-').toLowerCase()}-${randomId}.pdf`; // Replace spaces in title with dashes and convert to lowercase
//   };

//   // Handle checkout process and pass generated links
//   const handleCheckout = () => {
//     const totalAmountFanon = calculateTotalAmountInFanon();
  
//     // Generate download links for all items in the cart
//     const downloadLinks = cart.map(item => ({
//       title: item.title,
//       link: generateRandomLink(item.title), // Generate unique download link for each book
//     }));
  
//     // Ensure download links are correctly encoded in the URL
//     const downloadLinksParam = encodeURIComponent(JSON.stringify(downloadLinks));
  
//     // Redirect to payment form with the necessary parameters (amount and download links)
//     window.location.href = `/payment-form?amount=${totalAmountFanon}&downloadLinks=${downloadLinksParam}`;
//   };

//   return (
//     <div className="cart">
//       <h2 className="h2">YOUR CART</h2>
//       {cart.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <>
//           <ul>
//             {cart.map((item, index) => (
//               <li key={index} className="cart-item">
//                 {item.imagePath && (
//                   <img
//                     src={`http://195.35.48.252:4000/${item.imagePath}`}
//                     alt={item.title}
//                     className="cart-item-image"
//                   />
//                 )}
//                 <div className="cart-item-details">
//                   <h4>{item.title} by {item.author}</h4>
//                   <p>Price: {item.price} Fanon (USD {(item.price / USD_TO_FANON).toFixed(2)})</p>
//                 </div>
//                 <button
//                   className="remove-btn"
//                   onClick={() => onRemoveFromCart(item._id)}
//                 >
//                   Remove
//                 </button>
//               </li>
//             ))}
//           </ul>
//           <div className="cart-total">
//             <h3>Total Amount: {calculateTotalAmountInFanon()} Fanon (USD {calculateTotalAmountInUSD()})</h3>
//           </div>
//         </>
//       )}
//       <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
//       <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>
//     </div>
//   );
// };

// export default Cart;

// import React from 'react';
// import './Cart.css';

// const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
//   const USD_TO_FANON = 100;

//   // Calculate total in Fanon
//   const calculateTotalAmountInFanon = () => {
//     return cart.reduce((total, item) => total + item.price, 0);
//   };

//   // Calculate total in USD
//   const calculateTotalAmountInUSD = () => {
//     const totalFanon = calculateTotalAmountInFanon();
//     return (totalFanon / USD_TO_FANON).toFixed(2);
//   };

//   // Generate download link directly using the book ID
//   const generateDownloadLink = (bookId) => {
//     return `http://195.35.48.252:4000/download/${bookId}`;
//   };

//   // Handle checkout process and pass generated links
//   const handleCheckout = () => {
//     const totalAmountFanon = calculateTotalAmountInFanon();

//     // Generate download links for all items in the cart
//     const downloadLinks = cart.map(item => ({
//       title: item.title,
//       link: generateDownloadLink(item._id), // Generate download link based on book ID
//     }));

//     // Encode download links for the payment form URL
//     const downloadLinksParam = encodeURIComponent(JSON.stringify(downloadLinks));

//     // Redirect to payment form with necessary parameters (amount and download links)
//     window.location.href = `/payment-form?amount=${totalAmountFanon}&downloadLinks=${downloadLinksParam}`;
//   };

//   return (
//     <div className="cart">
//       <h2 className="h2">YOUR CART</h2>
//       {cart.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <>
//           <ul>
//             {cart.map((item, index) => (
//               <li key={index} className="cart-item">
//                 {item.imagePath && (
//                   <img
//                     src={`http://195.35.48.252:4000/${item.imagePath}`}
//                     alt={item.title}
//                     className="cart-item-image"
//                   />
//                 )}
//                 <div className="cart-item-details">
//                   <h4>{item.title} by {item.author}</h4>
//                   <p>Price: {item.price} Fanon (USD {(item.price / USD_TO_FANON).toFixed(2)})</p>
//                 </div>
//                 <button
//                   className="remove-btn"
//                   onClick={() => onRemoveFromCart(item._id)}
//                 >
//                   Remove
//                 </button>
//               </li>
//             ))}
//           </ul>
//           <div className="cart-total">
//             <h3>Total Amount: {calculateTotalAmountInFanon()} Fanon (USD {calculateTotalAmountInUSD()})</h3>
//           </div>
//         </>
//       )}
//       <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
//       <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>
//     </div>
//   );
// };

// export default Cart;


import React from 'react';
import './Cart.css';

const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
  const USD_TO_FANON = 100;

  // Calculate total in Fanon
  const calculateTotalAmountInFanon = () => {
    return cart.reduce((total, item) => total + item.price, 0);
  };

  // Calculate total in USD
  const calculateTotalAmountInUSD = () => {
    const totalFanon = calculateTotalAmountInFanon();
    return (totalFanon / USD_TO_FANON).toFixed(2);
  };

  // Generate download link directly using the book ID
  const generateDownloadLink = (bookId) => {
    return `http://195.35.48.252:4000/download/${bookId}.pdf`; // PDF download link
  };

  // Handle checkout process and pass generated links
// Inside Cart.js
const handleCheckout = () => {
  const totalAmountFanon = calculateTotalAmountInFanon();

  // Generate download links for all items in the cart
  const downloadLinks = cart.map(item => ({
    title: item.title,
    link: generateDownloadLink(item._id), // Generate download link based on book ID
  }));

  // Store download links in sessionStorage
  sessionStorage.setItem('downloadLinks', JSON.stringify(downloadLinks));

  // Redirect to PaymentPage with only the amount parameter
  window.location.href = `/payment-form?amount=${totalAmountFanon}`;
};

  return (
    <div className="cart">
      <h2 className="h2">YOUR CART</h2>
      {cart.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <>
          <ul>
            {cart.map((item, index) => (
              <li key={index} className="cart-item">
                {item.imagePath && (
                  <img
                    src={`http://195.35.48.252:4000/${item.imagePath}`}
                    alt={item.title}
                    className="cart-item-image"
                  />
                )}
                <div className="cart-item-details">
                  <h4>{item.title} by {item.author}</h4>
                  <p>Price: {item.price} Fanon (USD {(item.price / USD_TO_FANON).toFixed(2)})</p>
                </div>
                <button
                  className="remove-btn"
                  onClick={() => onRemoveFromCart(item._id)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
          <div className="cart-total">
            <h3>Total Amount: {calculateTotalAmountInFanon()} Fanon (USD {calculateTotalAmountInUSD()})</h3>
          </div>
        </>
      )}
      <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
      <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>
    </div>
  );
};

export default Cart;
