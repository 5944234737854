


// import React, { useEffect, useRef } from 'react';
// import Chart from 'chart.js/auto';

// const BookGraph2 = ({ data }) => {
//   const chartRef = useRef(null);
//   const chartInstanceRef = useRef(null);

//   useEffect(() => {
//     // If a chart already exists, destroy it before creating a new one
//     if (chartInstanceRef.current) {
//       chartInstanceRef.current.destroy();
//     }

//     const ctx = chartRef.current.getContext('2d');

//     // Create a bar chart with the provided data
//     chartInstanceRef.current = new Chart(ctx, {
//       type: 'line', // Bar chart type
//       data: {
//         labels: data.map(book => book.title), // Book titles as labels
//         datasets: [
//           {
//             label: 'Click Engagements',
//             data: data.map(book => book.engagements), // Engagement data
//             backgroundColor: [
//               'rgba(255, 99, 132, 0.6)',   // Red
//               'rgba(54, 162, 235, 0.6)',   // Blue
//               'rgba(255, 206, 86, 0.6)',   // Yellow
//               'rgba(75, 192, 192, 0.6)',   // Green
//               'rgba(153, 102, 255, 0.6)',  // Purple
//             ], // Different colors for each bar
//             borderColor: [
//               'rgba(255, 99, 132, 1)',     // Red border
//               'rgba(54, 162, 235, 1)',     // Blue border
//               'rgba(255, 206, 86, 1)',     // Yellow border
//               'rgba(75, 192, 192, 1)',     // Green border
//               'rgba(153, 102, 255, 1)',    // Purple border
//             ],
//             borderWidth: 1,
//           },
//         ],
//       },
//       options: {
//         responsive: true,
//         scales: {
//           y: {
//             beginAtZero: true, // Ensure Y-axis starts at 0
//           },
//         },
//       },
//     });
//   }, [data]);

  

//   return (
//     <div>
//       <canvas ref={chartRef} id="bookChart" />
//     </div>
//   );
// };

// export default BookGraph2;


import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BookGraph2 = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    // If a chart already exists, destroy it before creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    // Create a line chart with the provided data and additional datasets
    chartInstanceRef.current = new Chart(ctx, {
      type: 'line', // Line chart type
      data: {
        labels: data.map(book => book.title), // Book titles as labels
        datasets: [
          {
            label: 'Add to cart',
            data: data.map(book => book.engagements), // Engagement data
            backgroundColor: 'rgba(255, 99, 132, 0.6)', // Red
            borderColor: 'rgba(255, 99, 132, 1)', // Red border
            borderWidth: 2,
          },
          {
            label: 'Cover of eBook',
            data: data.map(() => Math.floor(Math.random() * 7)), // Random data
            backgroundColor: 'rgba(54, 162, 235, 0.6)', // Blue
            borderColor: 'rgba(54, 162, 235, 1)', // Blue border
            borderWidth: 2,
          },
          {
            label: 'Time spent on ebook',
            data: data.map(() => Math.floor(Math.random() * 5)), // Random data
            backgroundColor: 'rgba(255, 206, 86, 0.6)', // Yellow
            borderColor: 'rgba(255, 206, 86, 1)', // Yellow border
            borderWidth: 2,
          },
          {
            label: 'Show Bio',
            data: data.map(() => Math.floor(Math.random() * 8)), // Random data
            backgroundColor: 'rgba(75, 192, 192, 0.6)', // Green
            borderColor: 'rgba(75, 192, 192, 1)', // Green border
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true, // Ensure Y-axis starts at 0
          },
        },
      },
    });
  }, [data]);

  return (
    <div>
      <canvas ref={chartRef} id="bookChart" />
    </div>
  );
};

export default BookGraph2;
