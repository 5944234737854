// import React, { useState, useEffect } from 'react';
// import './Slideshow.css'; // Slideshow styling
// import artsImage from './images/pan20.jpg'; // Adjust the path as necessary

// const Slideshow = () => {
//   const slides = [
    
//     {
//       imagePath: './images/pan_africa_11.jpg',
//       quote: "“A people without knowledge of their past history, origin and culture is like a tree without roots.”",
//       art: 'Marcus Garvey',
//     },
    
//     {
//       imagePath: './images/pan_africa_22.jpg',
//       quote: "“I am not African because I was born in Africa but because Africa was born in me.” ",
//       art: 'Kwame Nkrumah',
//     },
//     {
//       imagePath: './images/pan_africa_3.jpg',
//       quote: "“We didn't want anybody telling us anything about Africa, much less calling us Africans. In hating Africa and in hating the Africans, we ended up hating ourselves, without even realizing it.” ",
//       art: 'Malcom X',
//     },
//     {
//         imagePath: './images/pan_africa_44.jpg',
//         quote: "“Our children may learn about heroes of the past. Our task is to make ourselves architects of the future.” ",
//         art: 'Mzee Jomo Kenyatta',
//       },
//       {
//           imagePath: './images/pan_africa_5.jpg',
//           quote: "“No one is going to give you the education you need to overthrow them. Nobody is going to teach you your true history, teach you your true heroes, if they know that the knowledge will help set you free.” ",
//           art: 'Assata Shakur',
//         },
//         {
//             imagePath: './images/pan_africa_6.jpg',
//             quote: "“The day will come when history will speak, Africa will write its own history, it will be a history of glory and dignity.” ",
//             art: 'Patrice Lumumba',
//           },
//           {
//               imagePath: './images/pan_africa_7.jpg',
//               quote: "“While revolutionaries as individuals can be murdered, you cannot kill ideas.” ",
//               art: 'Thomas Sankara',
//             },
//             {
//                 imagePath: './images/pan_africa_8.jpg',
//                 quote: "“There are powerful forces undermining progress in Africa. But one must never underestimate the power of the people to bring about change.” ",
//                 art: 'Ama Ata Aido',
//               },
//               {
//                   imagePath: './images/pan_africa_9.jpg',
//                   quote: "“The job of the conscious is to make the unconscious conscious.” ",
//                   art: 'Kwame Ture',
//                 },
//                 {
//                   imagePath: './images/Arts.jpeg',
//                   quote: "",
//                   art: 'Franz Owino ~ Artist',
//                 }
//     // Add more slides as needed, up to 10
//   ];

//   const [currentSlide, setCurrentSlide] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//     }, 20000);
//     return () => clearInterval(interval);
//   }, [slides.length]);

//   return (
//     <div className="slideshow-container" style={{ backgroundImage: `url(${artsImage})` }}>
//       <div className="slide-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
//         {slides.map((slide, index) => (
//           <div className="slide" key={index}>
//             <img
//               src={slide.imagePath}
//               alt="Pan-African imagery"
//               className="slide-image"
//             />
//             <div className="slide-content">
//               <p className="slide-quote">{slide.quote}</p>
//               <p className="slide-art">{slide.art}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Slideshow;

import React, { useState, useEffect } from 'react';
import './Slideshow.css'; // Slideshow styling
import artsImage from './images/pan20.jpg'; // Adjust the path as necessary

const Slideshow = () => {
  const slides = [
    {
      imagePath: './images/pan_africa_11.jpg',
      quote: "“A people without knowledge of their past history, origin and culture is like a tree without roots.”",
      art: '~ Marcus Garvey ~',
    },    
    {
      imagePath: './images/Arts1.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    {
      imagePath: './images/pan_africa_22.jpg',
      quote: "“I am not African because I was born in Africa but because Africa was born in me.” ",
      art: '~ Kwame Nkrumah ~',
    },
    
    {
      imagePath: './images/Arts2.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    {
      imagePath: './images/pan_africa_3.jpg',
      quote: "“We didn't want anybody telling us anything about Africa, much less calling us Africans. In hating Africa and in hating the Africans, we ended up hating ourselves, without even realizing it.” ",
      art: '~ Malcolm X ~',
    },
    
    {
      imagePath: './images/Arts3.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    {
      imagePath: './images/pan_africa_44.jpg',
      quote: "“Our children may learn about heroes of the past. Our task is to make ourselves architects of the future.”",
      art: '~ Mzee Jomo Kenyatta ~',
    },
    {
      imagePath: './images/pan_africa_5.jpg',
      quote: "“No one is going to give you the education you need to overthrow them. Nobody is going to teach you your true history, teach you your true heroes, if they know that the knowledge will help set you free.”",
      art: '~ Assata Shakur ~',
    },
    
    {
      imagePath: './images/Arts4.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    {
      imagePath: './images/pan_africa_6.jpg',
      quote: "“The day will come when history will speak, Africa will write its own history, it will be a history of glory and dignity.”",
      art: '~ Patrice Lumumba ~',
    },
    
    {
      imagePath: './images/Arts5.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    {
      imagePath: './images/pan_africa_7.jpg',
      quote: "“While revolutionaries as individuals can be murdered, you cannot kill ideas.”",
      art: '~ Thomas Sankara ~',
    },
    
    {
      imagePath: './images/Arts6.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    {
      imagePath: './images/pan_africa_8.jpg',
      quote: "“There are powerful forces undermining progress in Africa. But one must never underestimate the power of the people to bring about change.”",
      art: '~ Ama Ata Aido ~',
    },
    
    {
      imagePath: './images/Arts7.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    {
      imagePath: './images/pan_africa_9.jpg',
      quote: "“The job of the conscious is to make the unconscious conscious.”",
      art: '~ Kwame Ture ~',
    },
    
    {
      imagePath: './images/Arts8.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    
    {
      imagePath: './images/Arts9.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    
    {
      imagePath: './images/Arts10.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    
    {
      imagePath: './images/Arts11.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    
    {
      imagePath: './images/Arts12.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    
    {
      imagePath: './images/Arts13.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    },
    {
      imagePath: './images/Arts.jpeg',
      quote: "",
      art: '~ Franz Owano ~ Artist ~',
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // 20 seconds for each slide
    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="slideshow-container" style={{ backgroundImage: `url(${artsImage})` }}>
      <div className="slide-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            <img
              src={slide.imagePath}
              alt="Pan-African imagery"
              className="slide-image fade-in"
            />
            <div className="slide-content fade-in-text">
              <p className="slide-quote">{slide.quote}</p>
              <p className="slide-art">{slide.art}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
